import React from 'react';
import { Icon, Popup } from 'semantic-ui-react'
import SearchBar from './SearchBar';

function FilterSection({
    selectedGradeText,
    filteredProjects,
    handleGradeFilter,
    searchQuery,
    setSearchQuery,
    projects,
    setSuggestions,
    suggestions,
    setIsVisibleSearch,
    isVisibleSearch,
    resetAll,
    selectedGrade,
    inputValue,
    setInputValue,
    isVisible,
    resetStat
}) {
    // Form 컴포넌트와 이벤트 핸들러

    return (
        <div className={`bx ${searchQuery !=='' ? 'v1' : ''}`}>
            <div className="bx_sub">
                <h3>{selectedGradeText} ({filteredProjects.length})
                    &nbsp;
                    <Popup
                        trigger={<Icon name='info circle' />}
                        content='테이블 하단의 합계, 평균, 표준편차 데이터에는 계약이 종료된 프로젝트의 통계만 반영됩니다.'
                        on='click'
                        pinned
                        position='right center'
                        wide
                    />
                 </h3>
            </div>
            <div className='bx_sub'>
                <SearchBar
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    projects={projects}
                    filteredProjects={filteredProjects}
                    setSuggestions={setSuggestions}
                    suggestions={suggestions}
                    setIsVisibleSearch={setIsVisibleSearch}
                    isVisibleSearch={isVisibleSearch}
                    resetAll={resetAll}
                    selectedGrade={selectedGrade}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    isVisible={isVisible}
                    resetStat={resetStat}
                />
            </div>
            <div className="bx_sub">
                <select onChange={handleGradeFilter}>
                    <option value="all">전체</option>
                    <option value="1">프로모션 &middot; 브랜드 사이트</option>
                    <option value="2">기업 홈페이지 &middot; 서비스 개선 &middot; POC</option>
                    <option value="3">서비스 플랫폼 구축 (개발 포함)</option>
                    <option value="4">서비스 플랫폼 구축 (개발 미포함)</option>
                </select> 
            </div>
        </div>
    );
}

export default React.memo(FilterSection);
