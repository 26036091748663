import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useCollapse } from 'react-collapsed'
import ReactApexChart from 'react-apexcharts';
import { Icon, Popup } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ButtonSection from './ButtonSection';
import FormSection from './FormSection';
import TableSection from './TableSection';
import FilterSection from './FilterSection.js';
import StatisticsSection from './StatisticsSection';
import TabMenu from './TabMenu';
import {
    calculateAverage,
    calculateStdDev,
    calculateMinMax,
    formFields,
    gradeDescriptions,
    calculatePercentile,
    sanitizeForDB,
    graphColors,
    determineGrade,
    numericColumns,
    keys,
    keys2,
    formatDate,
    regExpPattern

} from './UtilityFunctions';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

import { useSelector, useDispatch } from 'react-redux';
import {
    getProjects,
    getProjects2,
    createProject,
    createProject2,
    updateProject,
    updateProject2,
    deleteProject,
    deleteProject2,
    fetchDataFailure,
    API_URL2,
    getProjectsRes,
    getProjectsRes2
} from '../actions/dataActions';
import UserGuide from './UserGuide';

const Guidelines = () => {

    const today = new Date().toISOString().split('T')[0];

    const initialMMState = {
        client_name: '',
        project_name: '',
        start_date: today,
        end_date: today,
        period: '',
        contract_hours: null,
        PM: null,
        planning: null,
        ui_design: null,
        markup: null,
        development: null,
        QA: null,
        grade: null,
        contract_status: null,
    };
      
    const [MM, setMM] = useState(initialMMState);
    
    const [login, setLogin] = useState();
    const [loginCheckExecuted, setLoginCheckExecuted] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedProjectName, setSelectedProjectName] = useState(null);

    const [differenceFromAverage, setDifferenceFromAverage] = useState(0);
    const [percentile, setPercentile] = useState(0);
    const [currentInputValue, setCurrentInputValue] = useState(0);

    const [calculatedContactHours, setCalculatedContactHours] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState('all');
    const [selectedGradeText, setSelectedGradeText] = useState('전체');
    const [calculationResult, setCalculationResult] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState('');
    
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);

    const [isContractWork, setIsContractWork] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');

    const [isExpanded, setExpanded] = useState(true)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

    const [empNo, setEmpNo] = useState(null);
    // const [empNm, setEmpNm] = useState(null);
    // const [deptCd, setDeptCd] = useState(null);

    const [isGuideShow, setIsGuideShow] = useState(false);

    const loginProcess = useCallback(async () => {
        try {
            const res = await axios.get(`${API_URL2}/api/user/me`, {
                withCredentials: true,
            });
            const data = res.data.data.info
            if (data.empNm !== '') {
                /* console.log(data.empNm);
                console.log(data.dutyNm);
                console.log(data.deptCd); */
                setEmpNo(data.empNo);
                // setEmpNm(data.empNm);
                // setDeptCd(data.dutyNm);
                if(data.dutyNm === "실장" || data.dutyNm === "본부장" || data.dutyNm === "센터장" || data.dutyNm === "대표이사" || data.deptCd === "219" || data.empNo === "HL0325"){
                // if(data.dutyNm === "본부장" || data.dutyNm === "센터장" || data.dutyNm === "대표이사" || data.empNo === "HL0325"){
                    setLogin(true);
                }else{
                    showToast(<p><span>서비스 접근 권한 오류입니다.</span> <a rel="noreferrer" href="https://mail.worksmobile.com/write/popup/?orderType=new&amp;subject=Estimate Guidelines 서비스 접근 권한 관련하여 문의합니다.&amp;body=<br>OOO 사유로 Estimate Guidelines 서비스 접근 권한이 필요하여 문의드립니다.<br>검토 의견 부탁드리겠습니다.<br><br>감사합니다.&amp;to=서비스 관리자 (Estimate Guidelines)<skyfishx@hivelab.co.kr>" target="_blank">skyfishx@hivelab.co.kr</a> 주소로 문의해주세요.</p>)
                }
            }
        } catch (error) {
            if (error.response.status === 440) {
                if (!loginCheckExecuted) {
                    setLoginCheckExecuted(true);
                    setLogin(false);
                    showToast('로그인 인증 오류입니다. HRMS 로그인 후에 다시 시도해주세요.')
                    setTimeout(() => {
                        window.open(`${API_URL2}/`);
                    }, 2000);
                }
            }
        }
    }, [loginCheckExecuted]);

    useEffect(() => {
        loginProcess();
    }, [loginProcess]);

    const handleGradeFilter = (e) => {
        const grade = e.target.value;
        setSelectedGrade(grade);
    
        const selectedText = e.target.options[e.target.selectedIndex].text;
        setSelectedGradeText(selectedText);
    
        // 필터링된 데이터 가져와서 min과 max를 업데이트
        if (grade === 'all') {
            const allValues = projects.map(project => project.contract_hours);
            const { min, max } = calculateMinMax(allValues);
            setMinValue(min);
            setMaxValue(max);
        } else {
            const filteredData = projects.filter(project => project.grade === grade);
            const filteredValues = filteredData.map(project => project.contract_hours);
            const { min, max } = calculateMinMax(filteredValues);
            setMinValue(min);
            setMaxValue(max);
        }
        
        resetAll();
    };

    const calculateTableValue = (value, isStdDev) => {
        if (selectedGrade === 'all') {
            return isStdDev ? `${stdDev.toFixed(2)}` : `${average.toFixed(2)}`;
        } else {
            const filteredData = projects.filter(project => project.grade === selectedGrade && project.contract_status === 'Y');
            const filteredValues = filteredData.map(project => project.contract_hours);
            const filteredAverage = calculateAverage(filteredValues);
            const filteredStdDev = calculateStdDev(filteredValues);

            if (isNaN(filteredAverage) || isNaN(filteredStdDev)) {
                return '';
            }

            return isStdDev ? `${filteredStdDev.toFixed(2)}` : `${filteredAverage.toFixed(2)}`;
        }
    };

    function findClosestProject(targetValue, projects) {
        if (projects.length === 0) {
            return null; // 프로젝트 목록이 비어있을 때 처리
        }
    
        let closestProject = projects[0]; // 초기 값 설정
    
        // 가장 가까운 프로젝트 찾기
        projects.forEach((project) => {
            const projectValue = project.contract_hours;
            const currentClosestValue = closestProject.contract_hours;
    
            if (Math.abs(targetValue - projectValue) < Math.abs(targetValue - currentClosestValue)) {
                closestProject = project;
            }
        });
    
        return closestProject;
    }

    /* function findMatchProject(targetProjectName, projects) {
        if (projects.length === 0) {
            return null; // 프로젝트 목록이 비어있을 때 처리
        }
    
        let matchProject = null; // 초기 값 설정
    
        // 동일한 프로젝트 찾기
        projects.forEach((project) => {
            if (project.project_name === targetProjectName) {
                matchProject = project;
            }
        });
    
        return matchProject;
    }
 */
    const handleCalculatedWorkChange = (value) => {

        const filteredProjects = selectedGrade === 'all' ? projects : projects.filter(project => project.grade === selectedGrade);
        const workHours = filteredProjects.map(project => project.contract_hours);
        const average = calculateAverage(workHours);
        const stdDev = calculateStdDev(workHours);
        const diffPercentage = ((value - average) / average) * 100;
        setDifferenceFromAverage(diffPercentage);
        const percentile = calculatePercentile(value, average, stdDev);
        setPercentile(percentile);
    };
    
    useEffect(() => {
        handleCalculatedWorkChange(currentInputValue)
        // eslint-disable-next-line
    }, [handleCalculatedWorkChange])

    const projects = useSelector(state => state.projects || []);
    const dispatch = useDispatch();
    const swiperRef = useRef(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [filteredProjects, setFilteredProjects] = useState([]);

    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);

    const [suggestions, setSuggestions] = useState([]);

    const [isVisibleSearch, setIsVisibleSearch] = useState(false);

    const [inputValue, setInputValue] = useState('');

    const fetchData = async () => {
        try {
            setMM(initialMMState);
            if(isContractWork){
                await dispatch(getProjects());
                
            }else{
                await dispatch(getProjects2());
            }
        } catch (error) {
            dispatch(fetchDataFailure(error));
        }
    };

    
    useEffect(() => {
       fetchData();
    // eslint-disable-next-line
    }, [isContractWork]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;

        if (numericColumns.includes(name)) {
            updatedValue = value || '';
        }

        setMM(prev => ({ ...prev, [name]: updatedValue}));
    };

    const prevFilteredProjectsLength = useRef(filteredProjects.length);

    const addSequentialNumbers = () => {
        const numberedProjects = filteredProjects.map((project, index) => ({
            ...project,
            no: index + 1
        }));
        return numberedProjects;
    };

    const numberedProjects = addSequentialNumbers();

    const setProjectPosition = () => {
        const matchingIndex = filteredProjects.findIndex((project) => project.project_name === MM.project_name);

        if (matchingIndex !== -1) {
            const tableElement = document.querySelector('.tbl_type2');

            // 스크롤 높이 설정
            const scrollHeight = Number(matchingIndex * 36.8); // 원하는 스크롤 높이

            // 테이블 요소의 부모 요소에 스크롤 높이 적용
            tableElement.parentNode.scrollTo({
                top: scrollHeight,
                behavior: 'smooth', // 부드럽게 스크롤하려면 'smooth'를 사용
            });
        }
    }


    useEffect(() => {
        if(filteredProjects.length > prevFilteredProjectsLength.current){
            handleSelectProject(MM.project_name);
        }
        prevFilteredProjectsLength.current = filteredProjects.length;
        // eslint-disable-next-line
    }, [filteredProjects]);

    const requiredFields = ['client_name', 'project_name', 'period', 'contract_hours', 'grade', 'contract_status'];
    const requiredFieldsName = ['고객사', '프로젝트', '기간', `${isContractWork ? '계약 공수' : '투입 공수'}`, '분류', '계약 종료'];

    const validateForm = () => {
        const missingField = requiredFields.filter((field) => !MM[field]);
    
        if (missingField.length > 0) {
            // 입력되지 않은 필드 중 첫 번째 필드의 인덱스를 찾기
            const firstMissingFieldIndex = requiredFields.findIndex((field) => field === missingField[0]);
    
            if (firstMissingFieldIndex !== -1) {
                alert(`"${requiredFieldsName[firstMissingFieldIndex]}" 필드는 필수 항목입니다.`);
    
                // 필드 인덱스에 해당하는 input 요소로 focus 이동
                const inputElements = document.getElementsByName(requiredFields[firstMissingFieldIndex]);
    
                if (inputElements.length > 0) {
                    inputElements[0].focus();
                }
            }
    
            return false;
        }
    
        const minDate = '2020-01-01';
    
        if (MM.end_date < MM.start_date) {
            alert("종료일은 시작일보다 과거로 설정할 수 없습니다. \n확인 후 다시 입력해주세요.");
            return false;
        }
    
        if (MM.start_date < minDate || MM.end_date < minDate) {
            alert("시작일과 종료일은 2020년 1월 1일 이후로 입력해주세요.");
            return false;
        }

        // contract_status의 유효성 검사 추가
        if (!['Y', 'N'].includes(MM.contract_status)) {
            alert(`계약 종료 필드는 'Y' 또는 'N' 중에 선택해야 합니다.`);

            // contract_status 필드에 해당하는 select 요소로 focus 이동
            const selectElement = document.getElementsByName('contract_status');

            if (selectElement.length > 0) {
                selectElement[0].focus();
            }

            return false;
        }

    
        return true;
    };

    
    
    const handleAddProject = () => {
        if (!validateForm()) {
            return;
        }

        const existingProject = projects.find((project) => project.project_name === MM.project_name);
    
        if (existingProject && selectedProject) {
            alert(`기존에 "${MM.project_name}" 이름으로 생성된 프로젝트가 존재합니다. \n신규 프로젝트를 등록하시려면 프로젝트 이름을 변경해주세요.`)
        } else {
            const shouldAddProject = window.confirm(`"${MM.project_name}" 신규 프로젝트를 생성하시겠습니까?`);
            if (shouldAddProject) {
                const sanitizedMM = sanitizeForDB(MM);
                if(isContractWork){
                    dispatch(createProject(sanitizedMM));
                }else{
                    dispatch(createProject2(sanitizedMM));
                }
            }else{
                return false
            }
        }
    };

    const handleUpdateProject = async () => {
        // console.log(MM)
        if (!selectedProject) {
            alert('선택된 프로젝트가 없습니다.');
            return;
        }

        if (!validateForm()) {
            return;
        }

        const existingProject = projects.find((project) => project.project_name === MM.project_name);
        if (existingProject && existingProject.id !== selectedProject.id) {
            alert(`"${MM.project_name}" 프로젝트명은 이미 존재합니다. 다른 이름으로 변경해주세요.`);
            return;
        }

        /* const shouldUpdateProject = window.confirm(`입력하신 값으로 "${MM.project_name}" 프로젝트 데이터를 변경하시겠습니까?`);
        if (shouldUpdateProject) { */
            if (selectedProject) {
                // console.log('MM : ', MM.contract_status)
                // console.log('selectedProject : ', selectedProject.contract_status)
                let userMsg = prompt('데이터를 변경하시려면 HL을 제외한 사원번호 4자리를 입력해주세요.');
                // console.log(empNo.slice(2,6), userMsg)
                
                if(empNo.slice(2,6) !== userMsg){
                    alert('입력하신 정보가 없거나 입력하신 사원번호가 로그인한 계정의 사원번호와 일치하지 않습니다. 다시 확인 후 시도해주세요.');
                }else{
                    const sanitizedMM = sanitizeForDB(MM);
                    if (isContractWork) {
                        dispatch(updateProject(selectedProject.id, sanitizedMM))
                            .then(() => {
                                setProjectPosition();
                                setSortColumn('start_date')
                                setSortDirection('descending')
                                // resetAll(); // dispatch가 완료된 이후에 실행됨
                            })
                            .catch((error) => {
                                // 오류 처리
                                console.error("Update project failed:", error);
                            });
                    } else {
                        dispatch(updateProject2(selectedProject.id, sanitizedMM))
                            .then(() => {
                                setProjectPosition();
                                setSortColumn('start_date')
                                setSortDirection('descending')
                                // resetAll(); // dispatch가 완료된 이후에 실행됨
                            })
                            .catch((error) => {
                                // 오류 처리
                                console.error("Update project failed:", error);
                            });
                    }
                }
                /* if (MM.contract_status !== selectedProject.contract_status) {
                    prompt
                    return;
                } */
                // console.log('filteredProjects : ', filteredProjects)   
            }
        
            // setMM(initialMMState);
        /* }else{
            return false
        } */
    };    

    const handleDeleteProject = (projectName) => {
        const projectToDelete = projects.find((project) => project.project_name === projectName);
        if (projectToDelete) {
            /* const confirmMessage = `선택한 프로젝트 "${projectName}"를 삭제하시겠습니까?`;
            const shouldDeleteProject = window.confirm(confirmMessage);
            if (shouldDeleteProject) { */
            let userMsg = prompt('데이터를 삭제하시려면 HL을 제외한 사원번호 4자리를 입력해주세요.');
            if(empNo.slice(2,6) !== userMsg){
                alert('입력하신 정보가 없거나 입력하신 사원번호가 로그인한 계정의 사원번호와 일치하지 않습니다. 다시 확인 후 시도해주세요.');
            }else{
                if(isContractWork){
                    dispatch(deleteProject(projectToDelete.id))
                    .then(() => {
                        resetAll();
                    }).catch((error) => {
                        console.error("Delete project failed:", error);
                    });
                }else{
                    dispatch(deleteProject2(projectToDelete.id)).then(() => {
                        resetAll();
                    }).catch((error) => {
                        console.error("Delete project failed:", error);
                    });
                }
            }
        }else{
            return false
        }
    };

    const [currentProjectData, setCurrentProjectData] = useState(null);
    const [comparedProjectData, setComparedProjectData] = useState(null);
    const [showComparedTable, setShowComparedTable] = useState(false);

    const handleCompareProject = async (projectName) => {
        const currentProjectData = projects.find((project) => project.project_name === projectName);
        // console.log(currentProjectData);
    
        try {
            let comparedProjectData;
            if (isContractWork) {
                const projectsData = await dispatch(getProjectsRes2());
                comparedProjectData = projectsData.find((project) => project.project_name === projectName);
                // console.log(comparedProjectData);
            } else {
                const projectsData = await dispatch(getProjectsRes());
                comparedProjectData = projectsData.find((project) => project.project_name === projectName);
                // console.log(comparedProjectData);
            }

            if (!comparedProjectData) {
                if (isContractWork) {
                    alert('투입 공수 데이터가 없습니다.');
                } else {
                    alert('배정 공수 데이터가 없습니다.');
                }
                return;
            }
    
            // 이후에 필요한 로직을 수행할 수 있습니다.
            // 예: setFilteredProjects(updatedProjects);
    
            // currentProjectData, comparedProjectData 상태 업데이트
            setCurrentProjectData(currentProjectData);
            setComparedProjectData(comparedProjectData);
    
            // 비교 테이블 표시
            setShowComparedTable(true);
        } catch (error) {
            console.error('Error comparing projects:', error);
            // 에러 처리 로직을 추가할 수 있습니다.
        }
    };

    const handleSelectProject = (projectName) => {
        const project = projects.find((project) => project.project_name === projectName);
        if (project) {
            setSelectedProject(project);
            setSelectedProjectName(projectName);
            setSelectedProjectId(project.id);
            setInputValue(project.project_name)

            const formattedStartDate = formatDate(project.start_date);
            const formattedEndDate = formatDate(project.end_date);


            const { id, start_date, end_date, ...projectData } = project;
            setMM({ start_date: formattedStartDate, end_date: formattedEndDate, ...projectData });
        }
    };

    const handleReset = () => {
        const shouldReset = window.confirm('입력값을 모두 초기화 하시겠습니까?');
        if (shouldReset) {
            resetAll();
        }else{
            return false
        }
    };

    const workHours = projects
    .filter(project => project.contract_status === 'Y')
    .map(project => project.contract_hours);

    const average = calculateAverage(workHours);
    const stdDev = calculateStdDev(workHours);

    const generateData = (mean, stdDev) => {
        const data = [];
    
        for (let i = -3; i < 3.1; i += 0.1) {
            const x = i * stdDev + mean;
            const y = Math.pow(Math.E, -Math.pow(i, 2) / 2) / Math.sqrt(2 * Math.PI);
            data.push({ x, y: Number(y.toFixed(3)) });
        }
        
        return data;
    };
    

    const getColumnData = (columnName) => projects.map(project => parseFloat(project[columnName]) || 0);

    // 합계를 계산하는 함수 (수정)
    const calculateColumnSum = (columnName) => {
        if (columnName === "project_name") return '';  // 프로젝트 컬럼은 계산에서 제외
        const filteredData = getColumnData(columnName).filter((value, index) => {
            return (selectedGrade === 'all' || projects[index].grade === selectedGrade) && (projects[index].contract_status === 'Y');
        });
        const sum = filteredData.reduce((a, b) => a + b, 0);
        return sum.toFixed(2);
    };


    // 평균을 계산하는 함수 (수정)
    const calculateColumnAverage = (columnName) => {
        if (columnName === "project_name") return '';  // 프로젝트 컬럼은 계산에서 제외
        const filteredData = getColumnData(columnName).filter((value, index) => {
            return (selectedGrade === 'all' || projects[index].grade === selectedGrade) && (projects[index].contract_status === 'Y');
        });
        const sum = calculateColumnSum(columnName);
        const count = filteredData.filter(value => value !== 0).length;

        if (count === 0) {
            return ''; // 모든 행이 빈 값을 가지면 빈 컬럼으로 반환
        } else {
            const average = sum / count;
            // return average.toFixed(2); // 소수점 1자리에서 반올림
            return Math.round(average * 100) / 100; // 소수점 1자리에서 반올림
        }
    };

    // 표준편차를 계산하는 함수 (수정)
    const calculateColumnStdDev = (columnName) => {
        if (columnName === "project_name") return '';  // 프로젝트 컬럼은 계산에서 제외
        const avg = parseFloat(calculateColumnAverage(columnName));
        const filteredData = getColumnData(columnName).filter((value, index) => {
            return (selectedGrade === 'all' || projects[index].grade === selectedGrade) && (projects[index].contract_status === 'Y');
        });
        const n = filteredData.length;
        let validDataCount = n;

        // 0으로 표기된 데이터를 제외
        for (const value of filteredData) {
            if (value === 0) {
                validDataCount--;
            }
        }

        if (validDataCount === 0) {
            return ''; // 데이터가 없으면 빈 컬럼으로 반환
        }

        const squareDiffs = filteredData.map(number => {
            if (number !== 0) {
                return Math.pow(number - avg, 2);
            } else {
                return 0; // 0인 데이터는 무시하고 0으로 처리
            }
        });

        const sumOfSquares = squareDiffs.reduce((a, b) => a + b, 0);
        const variance = sumOfSquares / validDataCount;
        const stdDev = Math.sqrt(variance);

        return Math.round(stdDev * 100) / 100; // 소수점 1자리에서 반올림
    };

    const jobTypeStats = keys.reduce((acc, key) => {
        acc[key] = {
            mean: calculateColumnAverage(key),
            stdDev: calculateColumnStdDev(key)
        };
        return acc;
    }, {});

    const resetAll = () => {
        setMM(initialMMState);
        setCurrentInputValue(0);
        setCalculatedContactHours(0);
        setIsVisible(false);
        setSelectedProject(null);
        setSelectedProjectName(null);
        setSortColumn('start_date');
        setSortDirection('descending');
        setInputValue('')
        setSearchQuery('');
        setSuggestions([]);
        setIsVisibleSearch(false);
        setShowComparedTable(false);
        resetTable();
        setShowAll(false);
    }

    const resetStat = () => {
        setCurrentInputValue(0);
        setCalculatedContactHours(0);
        setIsVisible(false);
    }

    /* const resetSort = () => {
        setSortColumn('start_date');
        setSortDirection('descending');
    } */

    const resetTable = () => {
        const updatedProjects = projects.filter((project) => (
            (selectedGrade === 'all' || project.grade === selectedGrade) &&
            (
                project.project_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                project.client_name.toLowerCase().includes(searchQuery.toLowerCase())
            )
        ));
    
        setFilteredProjects(updatedProjects);
    }

    useEffect(() => {
        setSortColumn('start_date');
        setSortDirection('descending');
    }, []);
    

    const calculateValues = () => {
        if (currentInputValue > 0) {
            setSearchQuery('')
            setSortColumn(null)
            setSortDirection(null)
            setIsVisibleSearch(false)
            handleCalculatedWorkChange(currentInputValue);
            setCalculatedContactHours(currentInputValue);
            const resultText = `산정하신 공수 ${currentInputValue}MM은 "${selectedGradeText}" 분류의 평균인 ${calculateTableValue(average, false)}MM 대비 ${differenceFromAverage.toFixed(1)}%의 차이를 보이며 백분위 분류 기준으로는 ${determineGrade(percentile)} 등급으로 ${gradeDescriptions[determineGrade(percentile)]} 수준입니다.
                                가장 근접한 공수의 프로젝트는 ${findClosestProject(currentInputValue, filteredProjects)?.contract_hours || '0'}MM인 "[${findClosestProject(currentInputValue, filteredProjects)?.client_name || ''}] ${findClosestProject(currentInputValue, filteredProjects)?.project_name || ''}" 입니다.`

            setCalculationResult(resultText);

            setIsVisible(true);
            handleSelectProject(findClosestProject(currentInputValue, filteredProjects)?.project_name);

            setProjectPosition();

        }else if(currentInputValue <= 0){
            alert('산정 공수 값은 양수로 입력해주세요.');
        }else{
            alert('산정 공수 값을 입력해주세요.');
        }
    };

    const preDefined = () => {
        setIsVisible2(true);
    }

    const calculateValuesReset = (shouldResetAllValues) => {
        if (shouldResetAllValues) {
            const shouldResetCalculate = window.confirm('산정 공수 입력값과 결과를 모두 초기화 하시겠습니까?');
            if (shouldResetCalculate) {
                resetAll();
            }
        } else {
            resetAll();
        }
    };

    // 3개 이상의 작업 유형 필터링
    const filteredJobTypes = Object.keys(jobTypeStats).filter(jobType => {
        const jobTypeCount = getColumnData(jobType).filter(value => value !== 0).length;
        return jobTypeCount >= 3;
    });

    // selectedGrade 또는 searchQuery가 변경될 때마다 filteredProjects 업데이트
    useEffect(() => {
        const updatedProjects = projects.filter((project) => (
            (selectedGrade === 'all' || project.grade === selectedGrade) &&
            (
                project.project_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                project.client_name.toLowerCase().includes(searchQuery.toLowerCase())
            )
        ));
    
        setFilteredProjects(updatedProjects);
    }, [selectedGrade, searchQuery, projects]);

    useEffect(() => {
        setProjectPosition();
        // eslint-disable-next-line
    }, [selectedProject]);


    const handleSort = (clickedColumn) => () => {
  
        if (sortColumn === clickedColumn) {
            // 이미 클릭한 컬럼이면 정렬 방향을 변경
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
            filteredProjects.reverse();
        } else {
            // 클릭한 컬럼으로 정렬하고 역순으로 설정
            setSortColumn(clickedColumn);
            setSortDirection('descending');  // 역순으로 설정
            if (clickedColumn === 'start_date' || clickedColumn === 'end_date') {
                // 날짜 컬럼 정렬
                filteredProjects.sort((a, b) => {
                    const dateA = new Date(a[clickedColumn]);
                    const dateB = new Date(b[clickedColumn]);
                    return dateB - dateA;  // 역순으로 정렬
                });
            } else if (typeof filteredProjects[0][clickedColumn] === 'number') {
                // 숫자형 데이터 컬럼 정렬
                filteredProjects.sort((a, b) => b[clickedColumn] - a[clickedColumn]);  // 역순으로 정렬
            } else {
                // 문자열 데이터 컬럼 정렬
                filteredProjects.sort((a, b) => {
                    if (a[clickedColumn] < b[clickedColumn]) {
                        return 1;  // 역순으로 정렬
                    }
                    if (a[clickedColumn] > b[clickedColumn]) {
                        return -1;  // 역순으로 정렬
                    }
                    return 0;
                });
            }
        }
    };

    const resetFilteredProjectsOrder = () => {
        setFilteredProjects(filteredProjects);
      
        // 정렬 상태를 초기화합니다.
        setSortColumn(null);
        setSortDirection(null);
      };
    

    const excludeColumns = ['client_name', 'project_name', 'start_date', 'end_date', 'grade', 'contract_status'];

    const [showAll, setShowAll] = useState(false);
    
    const toggleShowAll = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };

    useEffect(() => {
        document.body.style.overflow = (isGuideShow || showComparedTable) ? "hidden" : "inherit";

        return () => {
            document.body.style.overflow = "inherit";
        };
    }, [isGuideShow, showComparedTable]);

    useEffect(() => {
        const handleEscKey = (event) => {
          if (event.key === 'Escape') {
            if (showComparedTable) {
              setShowComparedTable(false);
            } else if (isGuideShow) {
              setIsGuideShow(false);
            }
          }
        };
      
        window.addEventListener('keydown', handleEscKey);

        return () => {
          window.removeEventListener('keydown', handleEscKey);
        };
      }, [setShowComparedTable, showComparedTable, setIsGuideShow, isGuideShow]);

    const showToast = (txt) => {
        toast.info(txt, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: 0.01,
          theme: "light",
        });
      };

    if (!login) {
        return (
            <div>
                {/* <p>HRMS 로그인 후 화면을 새로고침 하세요.</p> */}
                <ToastContainer/>
            </div>
        );
    }else{
    
        return (
            <div>
                <ButtonSection
                    isContractWork={isContractWork}
                    setIsContractWork={setIsContractWork}
                    setSelectedProject={setSelectedProject}
                    setSelectedProjectName={setSelectedProjectName}
                    setCurrentInputValue={setCurrentInputValue}
                    setCalculatedContactHours={setCalculatedContactHours}
                    setIsVisible={setIsVisible}
                    setMM={setMM}
                    sortColumn={sortColumn}
                    setSortColumn={setSortColumn}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    setShowComparedTable={setShowComparedTable}
                    isGuideShow={isGuideShow}
                    setIsGuideShow={setIsGuideShow}
                />
                <FormSection
                    formFields={formFields}
                    MM={MM}
                    handleInputChange={handleInputChange}
                    handleAddProject={handleAddProject}
                    handleUpdateProject={handleUpdateProject}
                    handleReset={handleReset}
                    isContractWork={isContractWork}
                    regExpPattern={regExpPattern}
                />
                
                <FilterSection
                    selectedGradeText={selectedGradeText}
                    filteredProjects={filteredProjects}
                    handleGradeFilter={handleGradeFilter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    projects={projects}
                    setSuggestions={setSuggestions}
                    suggestions={suggestions}
                    setIsVisibleSearch={setIsVisibleSearch}
                    isVisibleSearch={isVisibleSearch}
                    resetAll={resetAll}
                    selectedGrade={selectedGrade}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    isVisible={isVisible}
                    resetStat={resetStat}
                />
                
                <TableSection
                    projects={projects}
                    formFields={formFields}
                    filteredProjects={filteredProjects}
                    handleSelectProject={handleSelectProject}
                    handleDeleteProject={handleDeleteProject}
                    calculateColumnSum={calculateColumnSum}
                    calculateColumnAverage={calculateColumnAverage}
                    calculateColumnStdDev={calculateColumnStdDev}
                    isContractWork={isContractWork}
                    selectedProjectName={selectedProjectName}
                    selectedProjectId={selectedProjectId}
                    isVisible={isVisible}
                    calculateValuesReset={calculateValuesReset}
                    sortColumn={sortColumn}
                    setSortColumn={setSortColumn}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    inputValue={inputValue}
                    numberedProjects={numberedProjects}
                    handleSort={handleSort}
                    resetFilteredProjectsOrder={resetFilteredProjectsOrder}
                    excludeColumns={excludeColumns}
                    handleCompareProject={handleCompareProject}
                    setShowComparedTable={setShowComparedTable}
                    showComparedTable={showComparedTable}
                    currentProjectData={currentProjectData}
                    comparedProjectData={comparedProjectData}
                    searchQuery={searchQuery}
                    showAll={showAll}
                />

                {filteredProjects.length > 3 && (
                    <div className="btn_area v2">
                        <button onClick={toggleShowAll}>
                            {showAll ? (
                            <i className="icon angle up"><span className="blind">테이블 접기</span></i>
                            ) : (
                            <i className="icon angle down"><span className="blind">테이블 펼치기</span></i>
                            )}
                        </button>
                    </div>
                )}
                {/* <div className="btn_area">
                    <button
                        {...getToggleProps({
                            onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                        })}
                        >
                        {isExpanded ? '전체 통계 접기' : '전체 통계 펼치기'}
                    </button>
                </div> */}

                <h3 className='h_type2'>
                    <button
                        {...getToggleProps({
                            onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                        })}
                        >
                        {isExpanded ? (
                            <span><i className="dropdown icon"></i>전체 통계</span>
                        ) : (
                            
                            <span><i className="dropdown icon type"></i>전체 통계</span>
                        )}
                    </button>
                        &nbsp;
                        <Popup
                            trigger={<Icon name='info circle' />}
                            content='전체 통계 데이터에는 계약이 종료된 프로젝트의 통계만 반영됩니다.'
                            on='click'
                            pinned
                            position='right center'
                            wide
                    />
                </h3>

                <section className='section' {...getCollapseProps()}>
                    <StatisticsSection
                        calculateTableValue={calculateTableValue}
                        average={average}
                        stdDev={stdDev}
                        minValue={minValue}
                        maxValue={maxValue}
                        currentInputValue={currentInputValue}
                        setCurrentInputValue={setCurrentInputValue}
                        isVisible={isVisible}
                        differenceFromAverage={differenceFromAverage}
                        percentile={percentile}
                        determineGrade={determineGrade}
                        gradeDescriptions={gradeDescriptions}
                        filteredProjects={filteredProjects}
                        selectedGradeText={selectedGradeText}
                        calculateValues={calculateValues}
                        calculateValuesReset={calculateValuesReset}
                        calculationResult={calculationResult}
                        filteredJobTypes={filteredJobTypes}
                        jobTypeStats={jobTypeStats}
                        selectedProject={selectedProject}
                        calculatedContactHours={calculatedContactHours}
                        graphColors={graphColors}
                        swiperRef={swiperRef}
                        Swiper={Swiper}
                        Navigation={Navigation}
                        generateData={generateData}
                        SwiperSlide={SwiperSlide}
                        keys2={keys2}
                        ReactApexChart={ReactApexChart}
                        isContractWork={isContractWork}
                        findClosestProject={findClosestProject}
                        projects={projects}
                        setCalculatedContactHours={setCalculatedContactHours}
                        setIsVisible={setIsVisible}
                        handleSelectProject={handleSelectProject}
                        setSelectedProject={setSelectedProject}
                        setSelectedProjectName={setSelectedProjectName}
                        setCalculationResult={setCalculationResult}
                        isVisible2={isVisible2}
                        setIsVisible2={setIsVisible2}
                        preDefined={preDefined}
                        setMM={setMM}
                        setSearchQuery={setSearchQuery}
                        setIsVisibleSearch={setIsVisibleSearch}
                        setInputValue={setInputValue}
                        setSuggestions={setSuggestions}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        resetAll={resetAll}
                        regExpPattern={regExpPattern}
                    />
                </section>

                <TabMenu
                    filteredProjects={filteredProjects}
                    calculatedContactHours={calculatedContactHours}
                    isVisible={isVisible}
                    selectedProject={selectedProject}
                    selectedGrade={selectedGrade}
                    selectedGradeText={selectedGradeText}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    isContractWork={isContractWork}
                />
                
                {isGuideShow &&
                    <UserGuide
                        setIsGuideShow={setIsGuideShow}
                        isGuideShow={isGuideShow}
                    />
                }
                <div className={`dimmed ${isGuideShow ? 'on' : ''}`}></div>
                
            </div>
        );
    };
}

export default React.memo(Guidelines);