import React from 'react';
import { Button, Table } from 'semantic-ui-react';

function TableSection({
    projects,
    formFields,
    filteredProjects,
    handleSelectProject,
    handleDeleteProject,
    calculateColumnSum,
    calculateColumnAverage,
    calculateColumnStdDev,
    isContractWork,
    selectedProjectName,
    selectedProjectId,
    isVisible,
    calculateValuesReset,
    sortColumn,
    setSortColumn,
    sortDirection,
    setSortDirection,
    inputValue,
    numberedProjects,
    handleSort,
    resetFilteredProjectsOrder,
    excludeColumns,
    handleCompareProject,
    setShowComparedTable,
    showComparedTable,
    currentProjectData,
    comparedProjectData,
    searchQuery,
    showAll
}) {
    // Form 컴포넌트와 이벤트 핸들러
    function formatNumber(value) {
        // 소수점 둘째 자리에서 반올림하고, 0 또는 null이면 빈 문자열 반환
        return value ? Number(value).toFixed(2) : '';
    }

    /* const formatDate = (dateString) => {
        const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('ko-KR', options).replace(/\.$/, '').replace(/\s/g, '');
    }; */

    function calculatePercentage(currentHours, comparedHours) {
        if (!currentHours || !comparedHours || currentHours === 0 || comparedHours === 0) {
            return '';
        }

        return ((comparedHours / currentHours) * 100).toFixed(1) + '%';
    }

    function rtnBg(val) {
        if (val !== '') {
            let cssClass = ''
            if (val >= 90) {
                cssClass = 'idx_low'
            } else if (val >= 80 && val < 90) {
                cssClass = 'idx_mid'
            } else if (val >= 0 && val < 80) {
                cssClass = 'idx_high'
            }
            return cssClass
        }
    }

    const generateComparedTableData = () => {
        const [current, compared] = isContractWork ? [currentProjectData, comparedProjectData] : [comparedProjectData, currentProjectData];

        if (!current || !compared) {
            return false;
        }

        return (
            <Table.Row>
                {/* <Table.Cell>{current.client_name}</Table.Cell>
                <Table.Cell><span className='txt_type' title={current.project_name}>{current.project_name}</span></Table.Cell> */}
                <Table.Cell>{formatNumber(current.contract_hours)}</Table.Cell>
                <Table.Cell>{formatNumber(compared.contract_hours)}</Table.Cell>
                <Table.Cell>{formatNumber(current.contract_hours - compared.contract_hours)}</Table.Cell>
                <Table.Cell className={rtnBg(calculatePercentage(current.contract_hours, compared.contract_hours).slice(0, -1))}>
                    <span>{calculatePercentage(current.contract_hours, compared.contract_hours)}</span>
                </Table.Cell>
                <Table.Cell>{formatNumber(current.PM)}</Table.Cell>
                <Table.Cell>{formatNumber(compared.PM)}</Table.Cell>
                <Table.Cell>{formatNumber(current.PM - compared.PM)}</Table.Cell>
                <Table.Cell className={rtnBg(calculatePercentage(current.PM, compared.PM).slice(0, -1))}>
                    <span>{calculatePercentage(current.PM, compared.PM)}</span>
                </Table.Cell>
                <Table.Cell>{formatNumber(current.planning)}</Table.Cell>
                <Table.Cell>{formatNumber(compared.planning)}</Table.Cell>
                <Table.Cell>{formatNumber(current.planning - compared.planning)}</Table.Cell>
                <Table.Cell className={rtnBg(calculatePercentage(current.planning, compared.planning).slice(0, -1))}>
                    <span>{calculatePercentage(current.planning, compared.planning)}</span>
                </Table.Cell>
                <Table.Cell>{formatNumber(current.ui_design)}</Table.Cell>
                <Table.Cell>{formatNumber(compared.ui_design)}</Table.Cell>
                <Table.Cell>{formatNumber(current.ui_design - compared.ui_design)}</Table.Cell>
                <Table.Cell className={rtnBg(calculatePercentage(current.ui_design, compared.ui_design).slice(0, -1))}>
                    <span>{calculatePercentage(current.ui_design, compared.ui_design)}</span>
                </Table.Cell>
                <Table.Cell>{formatNumber(current.markup)}</Table.Cell>
                <Table.Cell>{formatNumber(compared.markup)}</Table.Cell>
                <Table.Cell>{formatNumber(current.markup - compared.markup)}</Table.Cell>
                <Table.Cell className={rtnBg(calculatePercentage(current.markup, compared.markup).slice(0, -1))}>
                    <span>{calculatePercentage(current.markup, compared.markup)}</span>
                </Table.Cell>
                <Table.Cell>{formatNumber(current.development)}</Table.Cell>
                <Table.Cell>{formatNumber(compared.development)}</Table.Cell>
                <Table.Cell>{formatNumber(current.development - compared.development)}</Table.Cell>
                <Table.Cell className={rtnBg(calculatePercentage(current.development, compared.development).slice(0, -1))}>
                    <span>{calculatePercentage(current.development, compared.development)}</span>
                </Table.Cell>
                <Table.Cell>{formatNumber(current.QA)}</Table.Cell>
                <Table.Cell>{formatNumber(compared.QA)}</Table.Cell>
                <Table.Cell>{formatNumber(current.QA - compared.QA)}</Table.Cell>
                <Table.Cell className={rtnBg(calculatePercentage(current.QA, compared.QA).slice(0, -1))}>
                    <span>{calculatePercentage(current.QA, compared.QA)}</span>
                </Table.Cell>
            </Table.Row>
        );
    };

    return (
        <div className={`bx2 ${showAll ? 'on' : ''}`}>
            {projects.length > 0 ? (
                <Table celled className='tbl_type2 unstackable sortable'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>No.</Table.HeaderCell>
                            {formFields.map((field, index) => (
                                <Table.HeaderCell
                                    key={index}
                                    sorted={sortColumn === field.name ? sortDirection : null}
                                    onClick={handleSort(field.name)}
                                    className={sortDirection === 'ascending' && sortColumn === field.name ? 'ascending' : ''}
                                >
                                    {field.name === 'contract_hours' ? (
                                        <span>{isContractWork ? '계약 공수' : '투입 공수'}</span>
                                    ) : (
                                        <span>{field.label}</span>
                                    )}
                                </Table.HeaderCell>
                            ))}
                            <Table.HeaderCell>데이터 조회/관리</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {searchQuery === ''&& (
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell>합계</Table.Cell>
                                <Table.Cell></Table.Cell>
                                {formFields.slice(1, -1).map((field) => {
                                    if (!excludeColumns.includes(field.name)) {
                                        return (
                                            <Table.Cell key={field.name} style={{ textAlign: 'right' }}>
                                                {calculateColumnSum(field.name)}
                                            </Table.Cell>
                                        );
                                    } else {
                                        return (
                                            <Table.Cell key={`empty-${field.name}`}></Table.Cell>
                                        );
                                    }
                                })}
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>평균</Table.Cell>
                                <Table.Cell></Table.Cell>
                                {formFields.slice(1, -1).map((field) => {
                                    if (!excludeColumns.includes(field.name)) {
                                        return (
                                            <Table.Cell key={field.name} style={{ textAlign: 'right' }}>
                                                {calculateColumnAverage(field.name)}
                                            </Table.Cell>
                                        );
                                    } else {
                                        return (
                                            <Table.Cell key={`empty-${field.name}`}></Table.Cell>
                                        );
                                    }
                                })}
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>표준편차</Table.Cell>
                                <Table.Cell></Table.Cell>
                                {formFields.slice(1, -1).map((field) => {
                                    if (!excludeColumns.includes(field.name)) {
                                        return (
                                            <Table.Cell key={field.name} style={{ textAlign: 'right' }}>
                                                {calculateColumnStdDev(field.name)}
                                            </Table.Cell>
                                        );
                                    } else {
                                        return (
                                            <Table.Cell key={`empty-${field.name}`}></Table.Cell>
                                        );
                                    }
                                })}
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    )}

                    <Table.Body>
                        {numberedProjects.length > 0 ? (
                            numberedProjects.map((project) => (
                                <Table.Row
                                    key={project.id}
                                    onClick={() => {
                                        if (!isVisible) {
                                            handleSelectProject(project.project_name);
                                        } else if (isVisible && selectedProjectName !== project.project_name && window.confirm('선택하신 프로젝트의 기본 통계 정보를 확인하려면 산정 공수 입력값과 결과를 초기화해야합니다. 진행하시겠습니까?')) {
                                            setSortColumn(null);
                                            setSortDirection(null);
                                            resetFilteredProjectsOrder();
                                            calculateValuesReset(false);
                                            handleSelectProject(project.project_name);
                                        }
                                    }}
                                    className={
                                        (selectedProjectName === project.project_name && selectedProjectId === project.id)
                                            ? 'on'
                                            : ''
                                    }
                                >
                                    <Table.Cell>{project.no}</Table.Cell>
                                    {formFields.map((field) => (
                                        <Table.Cell
                                            key={field.name}
                                            style={{ textAlign: (!isNaN(Number(project[field.name])) ? 'right' : 'left') }}
                                        >
                                            <span title={field.name === 'project_name' ? project[field.name] : ''}>
                                                {field.name === 'Grade'
                                                    ? project.grade
                                                    : field.name === 'start_date' || field.name === 'end_date'
                                                        ? project[field.name]
                                                            ? new Date(project[field.name]).toLocaleDateString('ko-KR', {
                                                                year: '2-digit',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                            }).replace(/\.$/, '')
                                                            : null
                                                        : project[field.name]}
                                            </span>
                                        </Table.Cell>
                                    ))}
                                    <Table.Cell>
                                        <Button onClick={() => handleCompareProject(project.project_name)} className='btn_type'>통계</Button>
                                        <Button onClick={() => handleDeleteProject(project.project_name)}>삭제</Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        ) : (
                            <Table.Row>
                                <Table.Cell colSpan={formFields.length + 2}>
                                    {/* <span className="txt">"{inputValue}"</span> 검색어가 포함된 프로젝트의 데이터가 없습니다. */}
                                    프로젝트 데이터가 없습니다.
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            ) : (
                <div>표시할 데이터가 없습니다.</div>
            )}

            {showComparedTable && currentProjectData && comparedProjectData ? (
                <div>
                    <div className='ly_pop on'>
                        <button
                            type="button"
                            className="btn_close"
                            onClick={() => {
                                setShowComparedTable(false);
                            }}>
                            <i className='close icon large'></i>
                        </button>
                        <h4>[{currentProjectData.client_name}] {currentProjectData.project_name}</h4>
                        {/* - {formatDate(currentProjectData.start_date)} ~ {formatDate(currentProjectData.end_date)} */}
                        <Table celled className='tbl_type2 v1 unstackable'>
                            <Table.Header>
                                <Table.Row>
                                    {/* <Table.HeaderCell rowSpan='2'>고객사</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan='2'>프로젝트명</Table.HeaderCell> */}
                                    <Table.HeaderCell colSpan='4'>전체</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='4'>PM</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='4'>기획</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='4'>UI디자인</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='4'>마크업</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='4'>개발</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='4'>QA</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    {/* PM, 기획, UI디자인, ... 역할들에 대한 계약, 투입, 잔여, 투입률 추가 */}
                                    {/* 예시로 4개씩 열 병합한 경우 */}
                                    <Table.HeaderCell>계약</Table.HeaderCell>
                                    <Table.HeaderCell>투입</Table.HeaderCell>
                                    <Table.HeaderCell>잔여</Table.HeaderCell>
                                    <Table.HeaderCell>투입률</Table.HeaderCell>
                                    <Table.HeaderCell>계약</Table.HeaderCell>
                                    <Table.HeaderCell>투입</Table.HeaderCell>
                                    <Table.HeaderCell>잔여</Table.HeaderCell>
                                    <Table.HeaderCell>투입률</Table.HeaderCell>
                                    <Table.HeaderCell>계약</Table.HeaderCell>
                                    <Table.HeaderCell>투입</Table.HeaderCell>
                                    <Table.HeaderCell>잔여</Table.HeaderCell>
                                    <Table.HeaderCell>투입률</Table.HeaderCell>
                                    <Table.HeaderCell>계약</Table.HeaderCell>
                                    <Table.HeaderCell>투입</Table.HeaderCell>
                                    <Table.HeaderCell>잔여</Table.HeaderCell>
                                    <Table.HeaderCell>투입률</Table.HeaderCell>
                                    <Table.HeaderCell>계약</Table.HeaderCell>
                                    <Table.HeaderCell>투입</Table.HeaderCell>
                                    <Table.HeaderCell>잔여</Table.HeaderCell>
                                    <Table.HeaderCell>투입률</Table.HeaderCell>
                                    <Table.HeaderCell>계약</Table.HeaderCell>
                                    <Table.HeaderCell>투입</Table.HeaderCell>
                                    <Table.HeaderCell>잔여</Table.HeaderCell>
                                    <Table.HeaderCell>투입률</Table.HeaderCell>
                                    <Table.HeaderCell>계약</Table.HeaderCell>
                                    <Table.HeaderCell>투입</Table.HeaderCell>
                                    <Table.HeaderCell>잔여</Table.HeaderCell>
                                    <Table.HeaderCell>투입률</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {generateComparedTableData()}
                            </Table.Body>
                        </Table>
                    </div>
                    <div className='dimmed on'></div>
                </div>
            ) : null
            }

        </div>
    );
}

export default React.memo(TableSection);