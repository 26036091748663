import React from 'react';
import { Table } from 'semantic-ui-react';
import { determineGrade } from './UtilityFunctions';

const GradeTable = ({ percentile, isVisible }) => {

    return (
        <Table celled className="tbl_type v1 unstackable">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>백분위(상위)</Table.HeaderCell>
                    <Table.HeaderCell>등급</Table.HeaderCell>
                    <Table.HeaderCell>가이드</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row className={determineGrade(percentile) === 1 && isVisible ? 'tr_type' : ''}>
                    <Table.Cell>0% ~ 4%</Table.Cell>
                    <Table.Cell>1</Table.Cell>
                    <Table.Cell rowSpan={2}>평균 대비 높음</Table.Cell>
                </Table.Row>
                <Table.Row className={determineGrade(percentile) === 2 && isVisible ? 'tr_type' : ''}>
                    <Table.Cell>4% ~ 11%</Table.Cell>
                    <Table.Cell>2</Table.Cell>
                </Table.Row>
                <Table.Row className={determineGrade(percentile) === 3 && isVisible ? 'tr_type' : ''}>
                    <Table.Cell>11% ~ 23%</Table.Cell>
                    <Table.Cell>3</Table.Cell>
                    <Table.Cell rowSpan={2}>평균 대비 다소 높음</Table.Cell>
                </Table.Row>
                <Table.Row className={determineGrade(percentile) === 4 && isVisible ? 'tr_type' : ''}>
                    <Table.Cell>23% ~ 40%</Table.Cell>
                    <Table.Cell>4</Table.Cell>
                </Table.Row>
                <Table.Row className={determineGrade(percentile) === 5 && isVisible ? 'tr_type' : ''}>
                    <Table.Cell>40% ~ 60%</Table.Cell>
                    <Table.Cell>5</Table.Cell>
                    <Table.Cell>평균 수준</Table.Cell>
                </Table.Row>
                <Table.Row className={determineGrade(percentile) === 6 && isVisible ? 'tr_type' : ''}>
                    <Table.Cell>60% ~ 77%</Table.Cell>
                    <Table.Cell>6</Table.Cell>
                    <Table.Cell rowSpan={2}>평균 대비 다소 낮음</Table.Cell>
                </Table.Row>
                <Table.Row className={determineGrade(percentile) === 7 && isVisible ? 'tr_type' : ''}>
                    <Table.Cell>77% ~ 89%</Table.Cell>
                    <Table.Cell>7</Table.Cell>
                </Table.Row>
                <Table.Row className={determineGrade(percentile) === 8 && isVisible ? 'tr_type' : ''}>
                    <Table.Cell>89% ~ 96%</Table.Cell>
                    <Table.Cell>8</Table.Cell>
                    <Table.Cell rowSpan={2}>평균 대비 낮음</Table.Cell>
                </Table.Row>
                <Table.Row className={determineGrade(percentile) === 9 && isVisible ? 'tr_type' : ''}>
                    <Table.Cell>96% ~ 100%</Table.Cell>
                    <Table.Cell>9</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

export default GradeTable;
