import React, { useState, useEffect } from 'react';
import ScatterChart from './ScatterChart';
import TreeMapChart from './TreeMapChart';
import { formFields } from './UtilityFunctions';

function TabMenu({ filteredProjects, calculatedContactHours, isVisible, selectedProject, selectedGrade, selectedGradeText, sortColumn, sortDirection, isContractWork }) {
  const [activeTab, setActiveTab] = useState('tab1');
  const [renderKey, setRenderKey] = useState(0); // State 값 변경을 통해 컴포넌트 재랜더링

  useEffect(() => {
    // filteredProjects 값이 업데이트될 때 renderKey를 변경하여 컴포넌트 다시 렌더링
    setRenderKey(prevKey => prevKey + 1);
  }, [filteredProjects, activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const showLabel = () => {
    if (sortColumn) {
      if (sortColumn === 'contract_hours' && isContractWork) {
        return '계약 공수';
      } else if (sortColumn === 'contract_hours' && !isContractWork) {
        return '투입 공수';
      } else {
        const field = formFields.find((field) => field.name === sortColumn);
        return field?.label || '';
      }
    } else {
      return '시작일';
    }
  };
  
  // 사용 예시
  // 정렬 : "{showLabel()}"
  
  
  return (
    <div>
      <div className="ui attached tabular menu tabmenu">
        <button
          className={activeTab === 'tab1' ? 'active item' : 'item'}
          onClick={() => handleTabClick('tab1')}
        >
          Scatter 차트
        </button>
        <button
          className={activeTab === 'tab2' ? 'active item' : 'item'}
          onClick={() => handleTabClick('tab2')}
        >
          TreeMap 차트
        </button>
        <p className="info_chart">
          정렬 : "{showLabel()}"
          {sortDirection === 'descending' || sortDirection === null ? ' 내림차순' : ' 오름차순'}
        </p>

      </div>
      <div className="tab-content">
        {activeTab === 'tab1' && (
          <ScatterChart
            key={renderKey} // renderKey가 변경될 때마다 ScatterChart가 다시 렌더링됨
            filteredProjects={filteredProjects}
            calculatedContactHours={calculatedContactHours}
            isVisible={isVisible}
            selectedProject={selectedProject}
            selectedGrade={selectedGrade}
            selectedGradeText={selectedGradeText}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
        )}
        {activeTab === 'tab2' && (
          <TreeMapChart
            key={renderKey} // renderKey가 변경될 때마다 TreeMapChart가 다시 렌더링됨
            filteredProjects={filteredProjects}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(TabMenu);
