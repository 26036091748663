import React from 'react';

function ButtonSection({
    isContractWork,
    setIsContractWork,
    setSelectedProject,
    setSelectedProjectName,
    setCurrentInputValue,
    setCalculatedContactHours,
    setIsVisible,
    setMM,
    sortColumn,
    setSortColumn,
    sortDirection,
    setSortDirection,
    setShowComparedTable,
    isGuideShow,
    setIsGuideShow
}) {
    // 버튼 컴포넌트와 이벤트 핸들러
    const handleButtonClick = async (isContract) => {
        setIsContractWork(isContract);
        try {
            setMM({
                client_name: null,
                project_name: null,
                start_date: '',
                end_date: '',
                period: null,
                contract_hours: null,
                PM: null,
                planning: null,
                ui_design: null,
                markup: null,
                development: null,
                QA: null,
                grade: null,
            });
            setCurrentInputValue(0);
            setCalculatedContactHours(0);
            setIsVisible(false);
            setSelectedProject(null);
            setSelectedProjectName(null);
            setSortColumn('start_date');
            setSortDirection('descending');
            setShowComparedTable(false);
            
          // 이제 새로운 모듈을 사용하여 상태가 변경될 때마다 올바른 함수를 사용할 수 있습니다.
        } catch (error) {
          // 모듈 가져오기 실패 처리
          console.error(error);
        }
    }

    const toggleGuideVisibility = () => {
        setIsGuideShow(!isGuideShow);
    };

    return (
        <div>
            <div className="bx5">
                {/* <div class="ui buttons"><button class="ui button v1">계약 공수</button><div class="or"></div><button class="ui positive button v1">투입 공수</button></div> */}
                <div className="ui buttons">
                    <button
                    className={`ui button v1${isContractWork ? ' active positive' : ''}`}
                    onClick={() => handleButtonClick(true)}
                    >
                    계약 공수
                    </button>
                    <div className="or"></div>
                    <button
                    className={`ui button v1${!isContractWork ? ' active positive' : ''}`}
                    onClick={() => handleButtonClick(false)}
                    >
                    투입 공수
                    </button>
                </div>
                <div className="guide_area">
                    <button type='button' className='btn_type' onClick={toggleGuideVisibility}>
                        <i className="icon info circle" /> 사용자 가이드
                    </button>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ButtonSection);
