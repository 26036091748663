import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import {
    formatDate,
    calculateMinMax
} from './UtilityFunctions';

const ScatterChart = ({ 
    filteredProjects,
    calculatedContactHours,
    isVisible,
    selectedProject,
    selectedGrade,
    selectedGradeText,
    sortColumn,
    sortDirection
}) => {

    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);

    useEffect(() => {
        // 계산을 위한 로직 추가
        if (filteredProjects.length > 0) {
            const allValues = filteredProjects.map((project) => project.contract_hours);
            const { min, max } = calculateMinMax(allValues);
            setMinValue(min);
            setMaxValue(max);
        }
    }, [filteredProjects, sortColumn, sortDirection]);

    // 트리맵 차트 데이터 예시
    // console.log(filteredProjects)

    /* filteredProjects.sort((a, b) => {
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);
        return dateA - dateB;
    }); */

    // let idxNumber = 0
    // console.log(selectedProject)
    // console.log(isVisible)
    const scatterChartData = filteredProjects.map((project, index) => {

        /* if (isVisible && selectedProject && selectedProject.project_name === project['project_name']) {
            idxNumber = index + 1;
            // console.log(idxNumber);
        } */
        
        /* const xValue =
          filteredProjects.length >= 10
            ? '[' + project['contract_hours'] + '] ' + project['client_name']
            : '[' + project['contract_hours'] + '] ' + project['project_name']; */
        // console.log(index)
        // const xValue = project['project_name'].slice(0,10)
        const xValue = index + 1

        return {
            x: xValue, // "프로젝트명" 열의 값
            y: project['contract_hours'], // "계약 공수" 열의 값
            a: project['PM'],
            b: project['planning'],
            c: project['ui_design'],
            d: project['markup'],
            f: project['development'],
            g: project['QA'],
            h: project['client_name'],
            i: project['project_name'],
            j: project['start_date'],
            k: project['end_date']
        }
    });

    const options = {
        chart: {
            type: 'scatter',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: false
            }
        },
        xaxis: {
            title: {
                // text: `프로젝트 No. (${filteredProjects[0]['start_date'].slice(0,-20)} ~ ${filteredProjects[filteredProjects.length - 1]['start_date'].slice(0,-20)})`,
                text: `${selectedGradeText} 프로젝트`,
                style: {
                    fontSize: '13px',
                    fontWeight: 'bold',
                    fontFamily: '나눔스퀘어라운드',
                }
            }
          },
        yaxis: {
            min: minValue-2,
            max: maxValue+2,
            labels: {
                formatter: function (value) {
                    if (typeof value !== 'undefined') {
                        return value.toFixed(0);
                    }
                    return ''; // 또는 다른 기본값
                }
            },
            title: {
                text: 'MM',
                style: {
                    fontSize: '13px',
                    fontWeight: 'normal',
                    fontFamily: '나눔스퀘어라운드'
                }
            }
          },
        markers: {
            size: 8,
            colors: [
                '#333'
            ],
            fillOpacity: 0.8
        },
        legend: {
            show: false, // 옵션에 따라 레전드를 표시하거나 숨길 수 있습니다.
        },
        dataLabels: {
            style: {
                colors: ['#333'],
                fontFamily: '나눔스퀘어라운드, Arial, sans-serif',
                fontWeight: 'bold', // 굵은 폰트
            }
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                // series[seriesIndex][dataPointIndex]에 선택된 데이터 포인트의 정보가 있습니다.
                // 원하는 형식으로 툴팁 정보를 구성합니다.
                // const dataPoint = series[seriesIndex][dataPointIndex];
                const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                return `
                    <div class="custom-tooltip-bx" style="padding: 10px;">
                        <h3><span class="txt2">[${data.h}] ${data.i} (${formatDate(data.j)} ~ ${formatDate(data.k)})</span></h3>
                        <table class="ui celled table tbl_type v2 unstackable">
                        <tbody>
                            <tr>
                            <th>전체 공수</th>
                            <td>${data.y || '0'}</td>
                            <th>PM</th>
                            <td>${data.a || '0'}</td>
                            </tr>
                            <tr>
                            <th>기획</th>
                            <td>${data.b || '0'}</td>
                            <th>UI디자인</th>
                            <td>${data.c || '0'}</td>
                            </tr>
                            <tr>
                            <th>마크업</th>
                            <td>${data.d || '0'}</td>
                            <th>FE</th>
                            <td>${data.e || '0'}</td>
                            </tr>
                            <tr>
                            <th>개발</th>
                            <td>${data.f || '0'}</td>
                            <th>QA</th>
                            <td>${data.g || '0'}</td>
                            </tr>
                        </tbody>
                        </table>
                        <span class="txt">(단위: MM)</span>
                    </div>
                `;
            },
        },


        /* title: {
          text: 'Scatter 차트',
        }, */
    };

    if(isVisible && selectedProject && selectedGrade === 'all'){
        const annotations = {
            yaxis: [
                {  
                    y: calculatedContactHours,
                    borderColor: '#FF0000',  // Choose a color for the annotation
                    yAxisIndex: 0,
                    label: {
                        show: true,
                        text: `산정 ${calculatedContactHours} MM`,
                        // text: `산정공수 상위 ${percentile.toFixed(1)}%`,
                        style: {
                            color: '#fff',
                            background: '#FF0000', // Matching the borderColor for consistency
                            fontFamily: '나눔스퀘어라운드',
                            fontSize: '12px',
                        },
                    },
                },
            ],
            /* xaxis: [
                {
                    x: idxNumber,
                    borderColor: '#FF0000',  // Choose a color for the annotation
                    yAxisIndex: 0,
                    label: {
                        show: true,
                        text: `산정 ${calculatedContactHours} MM`,
                        // text: `산정공수 상위 ${percentile.toFixed(1)}%`,
                        style: {
                            color: '#fff',
                            background: '#FF0000', // Matching the borderColor for consistency
                            fontFamily: '나눔스퀘어라운드',
                            fontSize: '12px',
                        },
                    },
                },
            ] */
        };
        options.annotations = annotations;
    }else{
        const annotations = {
            yaxis: [
                {
                    borderWidth: '0',
                },
            ],
            xaxis: [
                {
                    borderWidth: '0',
                },
            ]
        };
        options.annotations = annotations;
    }

    // filteredProjects가 비어 있는 경우에 대한 처리
    if (filteredProjects.length === 0) {
        return (
            <div>
                <Chart options={options} series={[]} type="scatter" width="1280" height="450" />
            </div>
        );
    }
    
    const series = [
        {
            data: scatterChartData,
        },
    ];

    return (
        <div>
            <Chart options={options} series={series} type="scatter" width="1280" height="450" />
        </div>
    );
};

export default React.memo(ScatterChart);