import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = 'https://estqa.hivelab.co.kr:3030';
export const API_URL2 = 'https://hrms.hivelab.co.kr';

export const FETCH_DATA_BEGIN = 'FETCH_DATA_BEGIN';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
export const LOAD_PROJECTS_ERROR = 'LOAD_PROJECTS_ERROR';

export const getProjects = () => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/projects`);
        // console.log(res.data);
        dispatch({ type: FETCH_DATA_SUCCESS, payload: res.data });
        
    } catch (error) {
        dispatch({ type: FETCH_DATA_FAILURE, error });
    }
}

export const getProjectsRes = () => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/projects`);
        return res.data;
        // console.log(res.data);
        // dispatch({ type: FETCH_DATA_SUCCESS, payload: res.data });
        
    } catch (error) {
        dispatch({ type: FETCH_DATA_FAILURE, error });
    }
}

export const getProjects2 = () => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/projects2`);
        // console.log(res.data);
        dispatch({ type: FETCH_DATA_SUCCESS, payload: res.data });
    } catch (error) {
        dispatch({ type: FETCH_DATA_FAILURE, error });
    }
}

export const getProjectsRes2 = () => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/projects2`);
        return res.data;
        // console.log(res.data);
        // dispatch({ type: FETCH_DATA_SUCCESS, payload: res.data });
    } catch (error) {
        dispatch({ type: FETCH_DATA_FAILURE, error });
    }
}

export const createProject = (project) => async dispatch => {
    try {
        const { id, ...projectWithoutId } = project;
        const res = await axios.post(`${API_URL}/projects`, projectWithoutId);
        dispatch({ type: 'CREATE_PROJECT_SUCCESS', payload: res.data });
        dispatch(getProjects()); // 프로젝트 목록 다시 가져오기
    } catch (error) {
        dispatch({ type: 'CREATE_PROJECT_ERROR', error });
    }
}

export const createProject2 = (project) => async dispatch => {
    try {
        const { id, ...projectWithoutId } = project;
        const res = await axios.post(`${API_URL}/projects2`, projectWithoutId);
        dispatch({ type: 'CREATE_PROJECT_SUCCESS', payload: res.data });
        dispatch(getProjects2()); // 프로젝트 목록 다시 가져오기
    } catch (error) {
        dispatch({ type: 'CREATE_PROJECT_ERROR', error });
    }
}

export const updateProject = (id, project) => async dispatch => {
    try {
        const res = await axios.put(`${API_URL}/projects/${id}`, project);
        dispatch({ type: 'UPDATE_PROJECT_SUCCESS', payload: res.data });
        dispatch(getProjects()); // 프로젝트 목록 다시 가져오기
    } catch (error) {
        dispatch({ type: 'UPDATE_PROJECT_ERROR', error });
    }
}

export const updateProject2 = (id, project) => async dispatch => {
    try {
        const res = await axios.put(`${API_URL}/projects2/${id}`, project);
        dispatch({ type: 'UPDATE_PROJECT_SUCCESS', payload: res.data });
        dispatch(getProjects2()); // 프로젝트 목록 다시 가져오기
    } catch (error) {
        dispatch({ type: 'UPDATE_PROJECT_ERROR', error });
    }
}

export const deleteProject = (id) => async dispatch => {
    try {
        const res = await axios.delete(`${API_URL}/projects/${id}`);
        dispatch({ type: 'DELETE_PROJECT_SUCCESS', payload: res.data });
        dispatch(getProjects()); // 프로젝트 목록 다시 가져오기
    } catch (error) {
        dispatch({ type: 'DELETE_PROJECT_ERROR', error });
    }
}

export const deleteProject2 = (id) => async dispatch => {
    try {
        const res = await axios.delete(`${API_URL}/projects2/${id}`);
        dispatch({ type: 'DELETE_PROJECT_SUCCESS', payload: res.data });
        dispatch(getProjects2()); // 프로젝트 목록 다시 가져오기
    } catch (error) {
        dispatch({ type: 'DELETE_PROJECT_ERROR', error });
    }
}

export const loadProjects = (data) => async dispatch => {
    try {
        dispatch({ type: LOAD_PROJECTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LOAD_PROJECTS_ERROR, error });
    }
}

export const fetchDataBegin = () => ({
    type: FETCH_DATA_BEGIN,
});

export const fetchDataSuccess = data => ({
    type: FETCH_DATA_SUCCESS,
    payload: { data },
});

export const fetchDataFailure = error => ({
    type: FETCH_DATA_FAILURE,
    payload: { error },
});

export function fetchData() {
    return dispatch => {
        dispatch(fetchDataBegin());

        return axios.get(`${API_URL}/projects`)
            .then(response => {
                dispatch(fetchDataSuccess(response.data));
            })
            .catch(error =>
                dispatch(fetchDataFailure(error))
            );
    };
}

export function fetchData2() {
    return dispatch => {
        dispatch(fetchDataBegin());

        return axios.get(`${API_URL}/projects2`)
            .then(response => {
                dispatch(fetchDataSuccess(response.data));
            })
            .catch(error =>
                dispatch(fetchDataFailure(error))
            );
    };
}

export const loadProjectsSuccess = data => ({
    type: LOAD_PROJECTS_SUCCESS,
    payload: data,
});

export const loadProjectsError = error => ({
    type: LOAD_PROJECTS_ERROR,
    payload: error,
});

