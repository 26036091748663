import React from 'react';
import { Table } from 'semantic-ui-react'; // Make sure to import the necessary components from your UI library

const UserGuide = ({setIsGuideShow, isGuideShow}) => {
    return (
        <div className='ly_pop2 on'>
            <h4 className='h_type3'>사용자 가이드</h4>
            <div className='ly_bx2'>
                <button
                    type="button"
                    className="btn_close"
                    onClick={() => {
                        setIsGuideShow(false);
                    }}>
                    <i className='close icon large'></i>
                </button>
                <Table celled className="tbl_type v4">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>분류</Table.HeaderCell>
                            <Table.HeaderCell>내용</Table.HeaderCell>
                            <Table.HeaderCell>상세</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell rowSpan={2}>접속</Table.Cell>
                            <Table.Cell>로그인</Table.Cell>
                            <Table.Cell>HRMS 로그인 인증</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>사용 권한</Table.Cell>
                            <Table.Cell>대표이사, 센터장, 본부장, 실장</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell rowSpan={2}>계약 공수</Table.Cell>
                            <Table.Cell>배정 공수</Table.Cell>
                            <Table.Cell>계약된 프로젝트 견적 기준의 배정 공수</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>산정 공수</Table.Cell>
                            <Table.Cell>비교 견적 시뮬레이션 목적으로 입력하는 산정 공수</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>투입 공수</Table.Cell>
                            <Table.Cell>투입 공수</Table.Cell>
                            <Table.Cell>최종 완료된 프로젝트에 투입된 확정 공수</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell rowSpan={7}>프로젝트 정보 추가/변경 폼</Table.Cell>
                            <Table.Cell>* 표시</Table.Cell>
                            <Table.Cell>필수 입력 항목</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>시작일 - 종료일</Table.Cell>
                            <Table.Cell>
                                기본값: "오늘"
                                <br />
                                시작일: 2020년도부터 입력 가능
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>기간(월)</Table.Cell>
                            <Table.Cell>시작일-종료일 기준의 프로젝트의 진행 기간(단위 : 월)</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>분류</Table.Cell>
                            <Table.Cell>
                                프로젝트 특성을 고려한 분류
                                <br />
                                1. 프로모션·브랜드 사이트
                                <br />
                                2. 기업 홈페이지·서비스 개선·POC
                                <br />
                                3. 서비스 플랫폼 구축 (개발 포함)
                                <br />
                                4. 서비스 플랫폼 구축 (개발 미포함)
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>계약 종료</Table.Cell>
                            <Table.Cell>프로젝트 계약 종료 여부 "Y/N" 선택</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>추가</Table.Cell>
                            <Table.Cell>
                                입력한 프로젝트 정보 기준으로 DB에 데이터를 추가하는 기능
                                <br />
                                프로젝트 정보 표에 추가한 프로젝트 데이터 활성화 표시 및 위치 이동
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>변경</Table.Cell>
                            <Table.Cell>
                                선택한 프로젝트의 정보 변경 (변경 버튼 선택 시 HL 제외한 사번 네자리 입력 필요)
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>프로젝트 검색</Table.Cell>
                            <Table.Cell>검색</Table.Cell>
                            <Table.Cell>고객사명 혹은 프로젝트명으로 검색</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell rowSpan={2}>프로젝트 분류 필터</Table.Cell>
                            <Table.Cell>반영 범위</Table.Cell>
                            <Table.Cell>
                                선택된 분류의 프로젝트 정보 표, 전체 통계, Scatter 차트, TreeMap 차트 변경
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>목록</Table.Cell>
                            <Table.Cell>
                                0. 전체<br />
                                1. 프로모션·브랜드 사이트<br />
                                2. 기업 홈페이지·서비스 개선·POC<br />
                                3. 서비스 플랫폼 구축 (개발 포함)<br />
                                4. 서비스 플랫폼 구축 (개발 미포함)
                            </Table.Cell>
                        </Table.Row>
                        

                        <Table.Row>
                            <Table.Cell rowSpan={6}>프로젝트 정보 표</Table.Cell>
                            <Table.Cell>정렬</Table.Cell>
                            <Table.Cell>기본값: "시작일" 내림 차순</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>제목 열</Table.Cell>
                            <Table.Cell>클릭 시 "선택한 제목 열" 기준 내림/오름 차순 정렬</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>하단 전체 통계</Table.Cell>
                            <Table.Cell>
                                합계 / 평균 / 표준편차 (단, "계약 종료 N"은 미포함)
                                <br />
                                테이블 제목 옆의 ⓘ 아이콘 클릭시 프로젝트 정보 표 하단 통계 산정 기준 설명 문구 노출
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>데이터 선택</Table.Cell>
                            <Table.Cell>
                                선택한 데이터 활성화 표시 및 프로젝트 정보 추가/변경 폼, 검색창, Bell Curve 통계 그래프와 데이터 동기화
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>데이터 조회/관리</Table.Cell>
                            <Table.Cell>
                                통계 - 선택한 프로젝트 정보 상세 조회 팝업 (계약/투입 내역, 투입률 등)
                                <br />
                                삭제 - 선택한 프로젝트 삭제 (삭제 버튼 선택 시 HL 제외한 사번 네자리 입력 필요)
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>테이블 접기/펼치기</Table.Cell>
                            <Table.Cell>
                                프로젝트 정보 표에 노출된 데이터가 4개 이상일 때 테이블 접기, 펼치기 버튼 노출 (▽, △)
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell rowSpan={9}>전체 통계</Table.Cell>
                            <Table.Cell>정보(ⓘ) 아이콘</Table.Cell>
                            <Table.Cell>
                                전체 통계 제목 옆의 ⓘ 아이콘 클릭시 하단 평균, 표준편차, 최소값, 최대값 정보 표 통계 산정 기준 설명 문구 노출
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>평균</Table.Cell>
                            <Table.Cell>프로젝트 분류 필터에서 선택한 분류에 해당하는 프로젝트들의 평균</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>표준편차</Table.Cell>
                            <Table.Cell>프로젝트 분류 필터에서 선택한 분류에 해당하는 프로젝트들의 표준편차</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>최소값</Table.Cell>
                            <Table.Cell>프로젝트 분류 필터에서 선택한 분류에 해당하는 프로젝트들의 최소값</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>최대값</Table.Cell>
                            <Table.Cell>프로젝트 분류 필터에서 선택한 분류에 해당하는 프로젝트들의 최대값</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>산정 공수</Table.Cell>
                            <Table.Cell>
                                공수 입력: 숫자와 소수점만 입력 가능
                                <br />
                                계산: 전체 통계의 평균 기준으로 평균 대비 입력한 공수의 백분위(상위), 등급, 프로젝트 수 계산 및 유사 프로젝트 통계 해석
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>백분위 등급 가이드</Table.Cell>
                            <Table.Cell>
                                상위 누적 비율을 고려한 9등급 기준으로 구성한 가이드 표
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Bell Curve 통계 그래프</Table.Cell>
                            <Table.Cell>
                                정규분포표를 활용한 산정 공수 대비 평균(계약, 투입) 비교 조회,
                                <br />
                                프로젝트 정보 표에서 선택한 프로젝트(전체, 직군별) 대비 평균을 조회할 수 있는 그래프
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>전체 통계 접기/펼치기</Table.Cell>
                            <Table.Cell>
                                프로젝트 정보 표의 제목 열의 정렬 기준에 따라 Scatter 차트, TreeMap 차트의 결과를 확인하기 쉽도록 제어하는 기능
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell rowSpan={2}>차트</Table.Cell>
                            <Table.Cell>Scatter 차트</Table.Cell>
                            <Table.Cell>
                                프로젝트 정보 표의 데이터로 시각화한 산포도 차트
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>TreeMap 차트</Table.Cell>
                            <Table.Cell>
                                프로젝트 정보 표의 데이터로 시각화한 계층구조 차트
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
};

export default UserGuide;
