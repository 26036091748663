import React, { useRef } from 'react';

const SearchBar = ({
        searchQuery,
        setSearchQuery,
        projects,
        filteredProjects,
        setSuggestions,
        suggestions,
        setIsVisibleSearch,
        isVisibleSearch,
        resetAll,
        selectedGrade,
        inputValue,
        setInputValue,
        isVisible,
        resetStat
    }) => {
    const suggestionsListRef = useRef(null);
    const inputRef = useRef(null);

    const handleInputChange = (e) => {
        const newValue = e.target.value;

        setInputValue(newValue); // 입력 값 업데이트

        // 검색어에 일치하는 프로젝트를 찾아서 suggestions 배열에 저장
        const searchedProjects = projects.filter((project) =>
            (selectedGrade === 'all' || project.grade === selectedGrade) &&
            (project.project_name.toLowerCase().includes(newValue.toLowerCase()) ||
                project.client_name.toLowerCase().includes(newValue.toLowerCase()))
        );

        const isWhitespace = /^\s*$/;

        if (isWhitespace.test(newValue) || (newValue !== '' && searchedProjects.length === 0)) {
            setSuggestions([]);
            setIsVisibleSearch(false);
        } else {
            // suggestions 업데이트
            setSuggestions(searchedProjects);
            setIsVisibleSearch(true);
        }
    };

    const handleSearchClick = () => {
        if(inputValue !==''){
            resetAll();
            setInputValue(inputValue)
            setSearchQuery(inputValue); // 검색어 업데이트
            setSuggestions([]); // suggestions 초기화
            setIsVisibleSearch(false); // 검색 결과 숨기기
        }else{
            resetAll();
            // alert('검색어를 입력해주세요.')
        }
    };

    const handleSuggestionClick = (suggestion) => {
        resetAll();
        setInputValue(suggestion.project_name);
        setSearchQuery(suggestion.project_name);
        setSuggestions([]);
        setIsVisibleSearch(false);

        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleSuggestionFocus = () => {
        if (suggestionsListRef.current && suggestionsListRef.current.children.length > 0) {
            const suggestionsItems = suggestionsListRef.current.children;
            const firstButton = suggestionsItems[0].querySelector('button');
    
            if (firstButton) {
                firstButton.focus();
            }
        }
    };

    const handleMoveFocus = (direction) => {
        if (suggestionsListRef.current && suggestionsListRef.current.children.length > 0) {
            const suggestionsItems = suggestionsListRef.current.children;
            const activeIndex = Array.from(suggestionsItems).findIndex((item) => item.firstChild === document.activeElement);
    
            let newIndex;
    
            if (direction === 'up') {
                newIndex = activeIndex > 0 ? activeIndex - 1 : suggestionsItems.length - 1;
            } else if (direction === 'down') {
                newIndex = activeIndex < suggestionsItems.length - 1 ? activeIndex + 1 : 0;
            }
    
            const newItem = suggestionsItems[newIndex];
            
            if (newItem && newItem.firstChild) {
                newItem.firstChild.focus();
            }
        }
    };
    
    const handleMoveFocusList = (e, project) => {
        const ARROW_UP_KEY_CODE = 38;
        const ARROW_UP_KEY_CODE_NUMPAD = 104;
        const ARROW_DOWN_KEY_CODE = 40;
        const ARROW_DOWN_KEY_CODE_NUMPAD = 98;
    
        // e.keyCode를 사용한 경우와 e.key를 사용한 경우를 함께 처리
        if (e.keyCode === ARROW_UP_KEY_CODE || e.keyCode === ARROW_UP_KEY_CODE_NUMPAD) {
            e.preventDefault();
            // Shift + Tab
            handleMoveFocus('up');
        } else if (e.keyCode === ARROW_DOWN_KEY_CODE || e.keyCode === ARROW_DOWN_KEY_CODE_NUMPAD) {
            e.preventDefault();
            // Tab
            handleMoveFocus('down');
        }
    };
    
    return (
        <div className='bx7'>
            <div className='ui input'>
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="프로젝트 검색"
                    value={inputValue}
                    onFocus = {() => {
                        if(inputValue === '' || isVisible){
                            resetStat();
                        }

                    }}
                    onChange={handleInputChange}
                    onClick={handleInputChange}
                    // onFocus={() => resetAll(false)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearchClick();
                        } else if (e.keyCode === 98 || e.keyCode === 40) {
                            e.preventDefault();
                            handleSuggestionFocus();
                        }
                    }}
                    className={'search'}
                />
            </div>
            {isVisibleSearch && (
                <div className="suggestions">
                    <div className='suggestions_area'>
                        {suggestions.length !== 0 ? (
                            <ul ref={suggestionsListRef}>
                                {suggestions.map((project) => (
                                    <li key={project.id}>
                                        <button
                                            type="button"
                                            onClick={() => handleSuggestionClick(project)}
                                            onKeyDown={handleMoveFocusList}
                                            /* onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleSuggestionClick(project)
                                                } else if (e.keyCode === 98 || e.keyCode === 40) {
                                                    e.preventDefault();
                                                    handleMoveFocus();
                                                }
                                            }} */
                                            dangerouslySetInnerHTML={{
                                                __html: `[${project.client_name.replace(
                                                    new RegExp(`(${inputValue})`, 'gi'),
                                                    (match, txt) => `<span class="txt">${txt}</span>`
                                                )}] ${project.project_name.replace(
                                                    new RegExp(`(${inputValue})`, 'gi'),
                                                    (match, txt) => `<span class="txt">${txt}</span>`
                                                )}`,
                                            }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <ul>
                                <li>
                                    <button type="button">{searchQuery}</button>
                                </li>
                            </ul>
                        )}
                    </div>
                    <div className="util_area">
                        <div className="util_bx">
                            <button type="button" className='ui button' onClick={() => setIsVisibleSearch(false)}>
                                닫기
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <button type="button" className='ui button btn_search' onClick={handleSearchClick}>검색</button>
        </div>
    );
};

export default SearchBar;
