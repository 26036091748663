import React, {useEffect} from 'react';
import Chart from 'react-apexcharts';

const TreeMapChart = ({ 
  filteredProjects,
  sortColumn,
  sortDirection
 }) => {
  // 트리맵 차트 데이터 예시
  // console.log(filteredProjects)

  /* filteredProjects.sort((a, b) => {
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
      return dateA - dateB;
  }); */

  useEffect(() => {
}, [filteredProjects, sortColumn, sortDirection]);

  
  const treeMapData = filteredProjects.map((project) => {
    /* const xValue =
      filteredProjects.length >= 10
        ? '[' + project['contract_hours'] + '] ' + project['client_name']
        : '[' + project['contract_hours'] + '] ' + project['project_name']; */

    const xValue = project['client_name']
  
    return {
      x: xValue, // "프로젝트명" 열의 값
      y: project['contract_hours'], // "계약 공수" 열의 값
      a: project['PM'],
      b: project['planning'],
      c: project['ui_design'],
      d: project['markup'],
      f: project['development'],
      g: project['QA'],
      h: project['project_name'],
    };
  });
  
  
  const options = {
    chart: {
      type: 'treemap',
      toolbar: {
        show: false,
      },
      animations: {
          enabled: false
      }
    },
    legend: {
      show: false, // 옵션에 따라 레전드를 표시하거나 숨길 수 있습니다.
    },
    dataLabels: {
      style: {
        colors: ['#000'],
        fontFamily: '나눔스퀘어라운드, Arial, sans-serif',
        fontWeight: 'normal',
        // fontSize: '13px',
      },
      formatter: function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ":  " + value
        const n = 5
        return value.length > n ? value.substr(0, n - 1) + '...' : value
      }
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // series[seriesIndex][dataPointIndex]에 선택된 데이터 포인트의 정보가 있습니다.
        // 원하는 형식으로 툴팁 정보를 구성합니다.
        // const dataPoint = series[seriesIndex][dataPointIndex];
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return `
          <div class="custom-tooltip-bx" style="padding: 10px;">
            <h3>${data.h}<span class="txt">(단위: MM)</span></h3>
            <table class="ui celled table tbl_type v2">
              <tbody>
                <tr>
                  <th>전체 공수</th>
                  <td>${data.y || '0'}</td>
                  <th>PM</th>
                  <td>${data.a || '0'}</td>
                </tr>
                <tr>
                  <th>기획</th>
                  <td>${data.b || '0'}</td>
                  <th>UI디자인</th>
                  <td>${data.c || '0'}</td>
                </tr>
                <tr>
                  <th>마크업</th>
                  <td>${data.d || '0'}</td>
                  <th>FE</th>
                  <td>${data.e || '0'}</td>
                </tr>
                <tr>
                  <th>개발</th>
                  <td>${data.f || '0'}</td>
                  <th>QA</th>
                  <td>${data.g || '0'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        `;
      },
    },
    colors : [
      '#3B93A5',
      '#F7B844',
      '#ADD8C7',
      '#EC3C65',
      '#CDD7B6',
      '#C1F666',
      '#7F94B0',
      '#EF6537',
      '#C0ADDB',
      '#D6D0B1',
      '#F98461',
      '#F5F669',
      '#F5B14C',
      '#A5D6A7',
      '#2F52E0',
      '#79B473',
      '#B66863',
      '#DC4E76',
      '#4170A2',
      '#D9BF77',
      '#DAB3F8',
      '#DE5454',
      '#98A13F',
      '#E2D04A',
      '#5E7B66',
      '#9EC4B2',
      '#D4E76D',
      '#04A777',
      '#AD7FEF',
      '#456EA0',
      '#B4C94C',
      '#D8AE52',
      '#C2E999',
      '#697E76',
      '#A538B0',
      '#00A1C9',
      '#BEBA67',
      '#A90671',
      '#0DB39E',
      '#4178E1',
      '#769F8A',
      '#80AED9',
      '#00B59C',
      '#97BC62',
      '#F18D69',
    ],
    
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
    /* title: {
      text: 'TreeMap 차트',
    }, */
  };
  
  // filteredProjects가 비어 있는 경우에 대한 처리
  if (filteredProjects.length === 0) {
    return (
        <div>
            <Chart options={options} series={[]} type="treemap" width="1280" height="450" />
        </div>
    );
}

  const series = [
    {
      data: treeMapData,
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="treemap" width="1280" height="450" />
    </div>
  );
};

export default React.memo(TreeMapChart);
