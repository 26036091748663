import 'react-toastify/dist/ReactToastify.css';

export const gradeDescriptions = {
    1: "평균 대비 높은",
    2: "평균 대비 높은",
    3: "평균 대비 다소 높은",
    4: "평균 대비 다소 높은",
    5: "평균",
    6: "평균 대비 다소 낮은",
    7: "평균 대비 다소 낮은",
    8: "평균 대비 낮은",
    9: "평균 대비 낮은",
};

export const formFields = [
    { name: 'client_name', label: '고객사' },
    { name: 'project_name', label: '프로젝트' },
    { name: 'start_date', label: '시작일' },
    { name: 'end_date', label: '종료일' },
    { name: 'period', label: '기간(월)' },
    { name: 'contract_hours', label: '' },
    { name: 'PM', label: 'PM' },
    { name: 'planning', label: '기획' },
    { name: 'ui_design', label: 'UI디자인' },
    { name: 'markup', label: '마크업' },
    { name: 'development', label: '개발' },
    { name: 'QA', label: 'QA' },
    { name: 'grade', label: '분류' },
    { name: 'contract_status', label: '계약 종료' }
];

export function calculateAverage(numbers) {
    const filteredNumbers = numbers.filter(num => !isNaN(num));
    const sum = filteredNumbers.reduce((a, b) => a + b, 0);
    return sum / filteredNumbers.length;
};

export function calculateStdDev(numbers) {
    const avg = calculateAverage(numbers);
    const squareDiffs = numbers.map(number => Math.pow(number - avg, 2));
    const avgSquareDiff = calculateAverage(squareDiffs);
    return Math.sqrt(avgSquareDiff);
};

export function calculateMinMax (numbers) {
    const filteredNumbers = numbers.filter(num => !isNaN(num));
    if (filteredNumbers.length === 0) {
        return { min: null, max: null };
    }
    const min = Math.min(...filteredNumbers);
    const max = Math.max(...filteredNumbers);
    return { min, max };
};

export function erf(x) {
    const a1 = 0.254829592;
    const a2 = -0.284496736;
    const a3 = 1.421413741;
    const a4 = -1.453152027;
    const a5 = 1.061405429;
    const p = 0.3275911;
    const sign = (x < 0) ? -1 : 1;
    x = Math.abs(x);
    const t = 1.0 / (1.0 + p * x);
    const y = (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t;
    return sign * (1 - y * Math.exp(-x * x));
}

export function calculatePercentile (value, mean, stdDev) {
    const diff = value - mean;
    const z = diff / stdDev;
    const percentileValue = 0.5 * (1 + erf(z / Math.sqrt(2)));
    return 100-(percentileValue * 100);
};

export function estimateRank (percentile, totalProjects) {
    const rank = Math.round(totalProjects * (1 - (percentile / 100)));
    return Math.max(rank, 1);
};

export function sanitizeForDB (obj) {
    const sanitizedObj = { ...obj };
    for (let key in sanitizedObj) {
        if (sanitizedObj[key] === '') {
            sanitizedObj[key] = null;
        }
    }
    return sanitizedObj;
}

export const graphColors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#4BC0C0',
    '#9966FF',
    '#FF9F40',
];

export function determineGrade (percentile) {
    if (percentile === '0.0') {
        return '1';
    }
    if (percentile >= 0 && percentile < 4) return 1;
    if (percentile >= 4 && percentile < 11) return 2;
    if (percentile >= 11 && percentile < 23) return 3;
    if (percentile >= 23 && percentile < 40) return 4;
    if (percentile >= 40 && percentile < 60) return 5;
    if (percentile >= 60 && percentile < 77) return 6;
    if (percentile >= 77 && percentile < 89) return 7;
    if (percentile >= 89 && percentile < 96) return 8;
    if (percentile >= 96 && percentile <= 100) return 9;
    return null;
};

export function formatDate (dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month starts from 0
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};


export const numericColumns = ['contract_hours', 'PM', 'planning', 'ui_design', 'markup', 'development', 'QA'];

export const keys = [
    "contract_hours",
    "PM",
    "planning",
    "ui_design",
    "markup",
    "development",
    "QA",
];

export const keys2 = [
    "계약 공수",
    "PM",
    "기획",
    "UI디자인",
    "마크업",
    "개발",
    "QA"
];

export function regExpPattern(value) {
    if (!/^\d*\.?\d*$/.test(value)) {
        alert('숫자 또는 소수점만 입력 가능합니다.');
        return false;
    }
    return true;
}