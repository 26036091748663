import React from 'react';
import { Button, Form, Input } from 'semantic-ui-react';

function FormSection({
    formFields,
    MM,
    handleInputChange,
    handleAddProject,
    handleUpdateProject,
    handleReset,
    isContractWork,
    regExpPattern
}) {
    const handleNumberInputChange = (e, { name, value }) => {
        // 정규 표현식을 사용하여 숫자 또는 소수점 입력되도록 체크
        if (!regExpPattern(value)) {
            return;
        }
        handleInputChange(e, { name, value });
    };
    

    return (
        <Form>
            {formFields.map((field, index) => (
                <Form.Field
                    key={index}
                    className={
                        field.name === 'client_name' ||
                        field.name === 'project_name' ||
                        field.name === 'period' ||
                        field.name === 'contract_hours' ||
                        field.name === 'grade' ||
                        field.name === 'contract_status'
                            ? 'required'
                            : ''
                    }
                >
                    {field.name === 'contract_status' ? ( 
                        <>
                            <label>계약 종료</label>
                            <select
                                name={field.name}
                                value={MM[field.name] || ''}
                                onChange={(e) => handleInputChange(e, { name: field.name, value: e.target.value })}
                            >
                                <option value="">선택</option>
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                                {/* 다른 옵션을 필요에 따라 추가 */}
                            </select>
                        </>
                    ) : (
                        <>
                            {field.name === 'contract_hours' ? (
                                <label>
                                    {isContractWork ? '계약 공수' : '투입 공수'}
                                </label>
                            ) : (
                                <label>{field.label}</label>
                            )}
                            <Input
                                type={field.name === 'start_date' || field.name === 'end_date' ? 'date' : 'text'}
                                name={field.name}
                                value={MM[field.name] || ''}
                                onChange={field.name === 'period' || field.name === 'contract_hours' || field.name === 'PM' || field.name === 'planning' || field.name === 'ui_design' || field.name === 'markup' || field.name === 'development' || field.name === 'QA' || field.name === 'grade' ? handleNumberInputChange : handleInputChange}
                                min={
                                    field.name === 'start_date'
                                        ? '2020-01-01'
                                        : field.name === 'end_date' && MM.start_date
                                        ? MM.start_date
                                        : undefined
                                }
                                input={{ autoComplete: 'off' }}
                            />
                        </>
                    )}

                </Form.Field>
            ))}
            <Button type="button" onClick={handleAddProject} className='btn_type2'>
                추가
            </Button>
            <Button type="button" onClick={handleUpdateProject} className='btn_type2'>
                변경
            </Button>
            {/* <Button type="reset" onClick={handleReset}>
                리셋
            </Button> */}
        </Form>
    );
}

export default React.memo(FormSection);
