import React, { useState, useEffect } from 'react';
// import Draggable from 'react-draggable';
import { Button, Form, Table, Input } from 'semantic-ui-react';
import { calculateMinMax } from './UtilityFunctions';
import GradeTable from './GradeTable';

function StatisticsSection({
    calculateTableValue,
    average,
    stdDev,
    currentInputValue,
    setCurrentInputValue,
    isVisible,
    isVisible2,
    differenceFromAverage,
    percentile,
    determineGrade,
    gradeDescriptions,
    filteredProjects,
    selectedGradeText,
    calculateValues,
    calculateValuesReset,
    calculationResult,
    filteredJobTypes,
    jobTypeStats,
    selectedProject,
    calculatedContactHours,
    graphColors,
    swiperRef,
    Swiper,
    Navigation,
    generateData,
    SwiperSlide,
    keys2,
    ReactApexChart,
    isContractWork,
    findClosestProject,
    projects,
    setCalculatedContactHours,
    setIsVisible,
    setIsVisible2,
    handleSelectProject,
    setSelectedProject,
    setSelectedProjectName,
    preDefined,
    setMM,
    formFields,
    setSearchQuery,
    setIsVisibleSearch,
    setInputValue,
    setSuggestions,
    setSortColumn,
    setSortDirection,
    resetAll,
    regExpPattern
}) {
    // Form 컴포넌트와 이벤트 핸들러

    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);
    const [isExpanded, setExpanded] = useState(false);

    useEffect(() => {
        // 계산을 위한 로직 추가
        if (filteredProjects.length > 0) {
            const allValues = filteredProjects
            .filter(project => project.contract_status === 'Y')
            .map((project) => project.contract_hours);
            const { min, max } = calculateMinMax(allValues);
            setMinValue(min);
            setMaxValue(max);
        }
    }, [filteredProjects]);

    const data = [
        { month: 1, PM: 0.72, planning: 0.94, design: 1.04, markup: 0.78, development: 1.30, QA: 0.30, total: 5.09 },
        { month: 2, PM: 1.44, planning: 1.88, design: 2.09, markup: 1.82, development: 2.60, QA: 0.63, total: 10.46 },
        { month: 3, PM: 2.16, planning: 2.82, design: 3.13, markup: 2.73, development: 3.90, QA: 0.94, total: 15.68 },
        { month: 4, PM: 2.88, planning: 3.76, design: 4.18, markup: 3.64, development: 5.20, QA: 1.26, total: 20.91 },
        { month: 5, PM: 3.60, planning: 4.70, design: 5.22, markup: 4.55, development: 6.50, QA: 1.57, total: 26.14 },
        { month: 6, PM: 4.32, planning: 5.64, design: 6.26, markup: 5.46, development: 7.80, QA: 1.89, total: 31.37 },
        { month: 7, PM: 5.04, planning: 6.58, design: 7.31, markup: 6.37, development: 9.10, QA: 2.20, total: 36.60 },
        { month: 8, PM: 5.76, planning: 7.52, design: 8.35, markup: 7.28, development: 10.40, QA: 2.52, total: 41.82 },
        { month: 9, PM: 6.48, planning: 8.46, design: 9.40, markup: 8.19, development: 11.70, QA: 2.83, total: 47.05 },
        { month: 10, PM: 7.20, planning: 9.40, design: 10.44, markup: 9.10, development: 13.00, QA: 3.15, total: 52.28 },
        { month: 11, PM: 7.92, planning: 10.34, design: 11.48, markup: 10.01, development: 14.30, QA: 3.46, total: 57.51 },
        { month: 12, PM: 8.64, planning: 11.28, design: 12.53, markup: 10.92, development: 15.60, QA: 3.77, total: 62.74 },
      ];

    isContractWork ? keys2[0] = '계약 공수 ' : keys2[0] = '투입 공수 ';

    return (
        <div className={`bx3 ${isVisible ? 'v1' : ''}`}>
            <div className="bx4">
                <Table celled className="tbl_type unstackable">
                    <Table.Body>
                        <Table.Row>
                            <Table.HeaderCell>평균</Table.HeaderCell>
                            <Table.Cell style={{ textAlign: 'right' }}>{calculateTableValue(average, false)}</Table.Cell>
                            <Table.HeaderCell>표준편차</Table.HeaderCell>
                            <Table.Cell style={{ textAlign: 'right' }}>{calculateTableValue(stdDev, true)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>최소값</Table.HeaderCell>
                            <Table.Cell style={{ textAlign: 'right' }}>
                                {minValue !== null ? minValue.toFixed(2) : ''}
                            </Table.Cell>
                            <Table.HeaderCell>최대값</Table.HeaderCell>
                            <Table.Cell style={{ textAlign: 'right' }}>
                                {maxValue !== null ? maxValue.toFixed(2) : ''}
                            </Table.Cell>
                        </Table.Row>

                    </Table.Body>
                </Table>
                <Form>
                    <Form.Field>
                        <label className="lbl_type">
                            <span>산정 공수</span>
                            {/* <button type="button" className='btn_type' onClick={preDefined}>
                                <i className="icon question circle"></i>
                            </button> */}
                        </label>
                        <Input
                            type="text"
                            placeholder="공수 입력"
                            value={currentInputValue === 0 ? '' : currentInputValue}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (!regExpPattern(inputValue)) {
                                    return;
                                }
                                setCurrentInputValue(inputValue);
                            }}
                            
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    calculateValues();
                                }else {
                                    setIsVisible(false);
                                }
                            }}
                            onFocus={() => {
                                resetAll();
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>평균 대비</label>
                        <Input
                        type="text"
                        value={isVisible ? `${differenceFromAverage.toFixed(1)}%` : ''}
                        readOnly
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>백분위(상위)</label>
                        <Input
                        type="text"
                        value={isVisible ? `${percentile.toFixed(1)}%` : ''}
                        readOnly
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>등급</label>
                        <Input
                        type="text"
                        value={isVisible ? determineGrade(percentile) : ''}
                        readOnly
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>프로젝트 수</label>
                        <Input
                        type="text"
                        value={isVisible ? filteredProjects.length : ''}
                        readOnly
                        />
                    </Form.Field>
                    {/* <Form.Field>
                        <label>순위</label>
                        <Input type="text" value={`${rank}/${filteredProjects.length}`} readOnly />
                    </Form.Field> */}
                    <Button type="button" onClick={calculateValues}>계산</Button>
                    {/* <Button type="reset" onClick={calculateValuesReset}>리셋</Button> */}
                </Form>

                <GradeTable
                    isVisible={false}
                    percentile={percentile}
                 />                
                
                {calculationResult && isVisible && (
                // <Draggable>
                    <div className="dsc">
                        <div className={isVisible ? 'dsc_type' : ''}>
                            <h3>통계 해석</h3>
                            <button type="button" className="btn_close" onClick={() => {
                                setCurrentInputValue(0);
                                setCalculatedContactHours(0);
                                setIsVisible(false);
                                setSelectedProject(null);
                                setSelectedProjectName(null);
                                setExpanded(true)
                            }}>X</button>
                            <div className={`ly_bx ${isExpanded ? 'expand' : ''}`}>
                                {calculationResult}
                                <div className="custom-tooltip-bx v1">
                                    <h4>[유사 프로젝트] {findClosestProject(currentInputValue, filteredProjects)?.project_name || ''}<span className="txt">(단위: MM)</span></h4>
                                    <table className="ui celled table tbl_type v2 unstackable">
                                        <tbody>
                                            <tr>
                                                <th>전체공수</th>
                                                <td>{findClosestProject(currentInputValue, filteredProjects)?.contract_hours || '0'}</td>
                                                <th>PM</th>
                                                <td>{findClosestProject(currentInputValue, filteredProjects)?.PM || '0'}</td>
                                            </tr>
                                            <tr>
                                                <th>기획</th>
                                                <td>{findClosestProject(currentInputValue, filteredProjects)?.planning || '0'}</td>
                                                <th>UI디자인</th>
                                                <td>{findClosestProject(currentInputValue, filteredProjects)?.ui_design || '0'}</td>
                                            </tr>
                                            <tr>
                                                <th>마크업</th>
                                                <td>{findClosestProject(currentInputValue, filteredProjects)?.markup || '0'}</td>
                                                <th>FE</th>
                                                <td>{findClosestProject(currentInputValue, filteredProjects)?.FE || '0'}</td>
                                            </tr>
                                            <tr>
                                                <th>개발</th>
                                                <td>{findClosestProject(currentInputValue, filteredProjects)?.development || '0'}</td>
                                                <th>QA</th>
                                                <td>{findClosestProject(currentInputValue, filteredProjects)?.QA || '0'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <GradeTable
                                        isVisible={isVisible}
                                        percentile={percentile}
                                    />
                                </div>
                            </div>
                            <div className='btn_section'>
                                <div className="btn_area v1">
                                    <button onClick={() => setExpanded(!isExpanded)}>
                                        {isExpanded ? (
                                            <i className="icon angle up"><span className="blind">통계 해석 접기</span></i>
                                        ) : (
                                            <i className="icon angle down"><span className="blind">통계 해석 펼치기</span></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                // </Draggable>
                )}
            </div>
            <div className="bx4">
                {isVisible2 && (
                    <div className="bx6">
                        <h3>기간 견적 제출용 데이터 (Beta)</h3>
                        <button type="button" className="btn_close v1" onClick={() => {
                            setIsVisible2(false)
                        }}>X</button>
                        <Table celled className="tbl_type v1 v3">
                            <Table.Header>
                                <Table.Row>
                                <Table.HeaderCell>기간(개월)</Table.HeaderCell>
                                <Table.HeaderCell>PM</Table.HeaderCell>
                                <Table.HeaderCell>기획</Table.HeaderCell>
                                <Table.HeaderCell>디자인</Table.HeaderCell>
                                <Table.HeaderCell>마크업</Table.HeaderCell>
                                <Table.HeaderCell>개발</Table.HeaderCell>
                                <Table.HeaderCell>QA</Table.HeaderCell>
                                <Table.HeaderCell>합계</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {data.map((row, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell>{row.month}</Table.Cell>
                                    <Table.Cell>{row.PM}</Table.Cell>
                                    <Table.Cell>{row.planning}</Table.Cell>
                                    <Table.Cell>{row.design}</Table.Cell>
                                    <Table.Cell>{row.markup}</Table.Cell>
                                    <Table.Cell>{row.development}</Table.Cell>
                                    <Table.Cell>{row.QA}</Table.Cell>
                                    <Table.Cell>{row.total}</Table.Cell>
                                </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                )}
                <div className="swiper-container">
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={50}
                        navigation={true}
                        modules={[Navigation]}
                        pagination={{ clickable: true }}
                        slidesPerView={1}
                    >
                        {filteredJobTypes.map((jobType, index) => {
                            const series = [
                                {
                                    name: keys2[index] !== 0 ? keys2[index] : '',
                                    data: generateData(jobTypeStats[jobType].mean, jobTypeStats[jobType].stdDev),
                                },
                            ];

                            const annotations = {
                                xaxis: [
                                    {
                                        x: jobTypeStats[jobType].mean,
                                        strokeDashArray: 0,
                                        borderColor: '#000000',
                                        label: {
                                            borderColor: '#000000',
                                            style: {
                                                color: '#fff',
                                                background: '#000000',
                                                fontFamily: '나눔스퀘어라운드',
                                                fontSize: '12px'
                                            },
                                            // text: `${keys2[index] !== 0 ? keys2[index] : ''} 평균 : ${jobTypeStats[jobType].mean}MM`,
                                            text: `평균 : ${jobTypeStats[jobType].mean}MM`,
                                        },
                                    },
                                ]
                            };
                            
                            if (selectedProject && selectedProject[jobType]) {
                                annotations.xaxis.push({
                                    x: selectedProject[jobType],
                                    borderColor: '#999',
                                    yAxisIndex: 0,
                                    label: {
                                        show: true,
                                        text: `${selectedProject.project_name} : ${selectedProject[jobType]} MM`,
                                        style: {
                                            color: '#fff',
                                            background: '#775DD0',
                                            fontFamily: '나눔스퀘어라운드',
                                            fontSize: '12px'
                                        },
                                        /* position:'right',
                                        offsetY:-10 */
                                    },
                                });
                            }else if (selectedProject && selectedProject[jobType] === null){
                                annotations.xaxis.push({
                                    x: jobTypeStats[jobType].mean,
                                    borderColor: '#999',
                                    yAxisIndex: 0,
                                    label: {
                                        show: true,
                                        text: `${isContractWork ? '계약 공수' : '투입 공수'} 없음`,
                                        style: {
                                            color: '#fff',
                                            background: '#775DD0',
                                            fontFamily: '나눔스퀘어라운드',
                                            fontSize: '12px'
                                        },
                                        position:'left',
                                        offsetY:-190
                                    },
                                });
                            }

                            if (calculatedContactHours && currentInputValue !==0 && jobType === 'contract_hours') {
                                annotations.xaxis.push({
                                    x: calculatedContactHours,
                                    borderColor: '#FF0000',  // Choose a color for the annotation
                                    
                                    label: {
                                        show: true,
                                        text: `산정 ${calculatedContactHours} MM`,
                                        // text: `산정공수 상위 ${percentile.toFixed(1)}%`,
                                        style: {
                                            color: '#fff',
                                            background: '#FF0000', // Matching the borderColor for consistency
                                            fontFamily: '나눔스퀘어라운드',
                                            fontSize: '12px'
                                        },
                                    },
                                });
                            }

                            const chartOptions = {
                                annotations: annotations,
                                chart: {
                                    type: 'line',
                                    zoom: {
                                        enabled: false,
                                    },
                                    toolbar: {
                                        show: false,
                                    }
                                },
                                colors: [graphColors[index % graphColors.length]],
                                dataLabels: {
                                    enabled: false,
                                },
                                stroke: {
                                    curve: 'smooth',
                                },
                                xaxis: {
                                    type: 'numeric',
                                    min: jobTypeStats[jobType].mean - 4 * jobTypeStats[jobType].stdDev, // Add this
                                    max: jobTypeStats[jobType].mean + 4 * jobTypeStats[jobType].stdDev, // Add this
                                    labels: {
                                        formatter: (value) => {
                                            const sigmaValue = (value - jobTypeStats[jobType].mean) / jobTypeStats[jobType].stdDev;
                                            const sigma = Math.round(sigmaValue);
                                            return `${sigma}σ`;
                                        },
                                    },
                                },
                            };

                            return (
                                <SwiperSlide key={jobType}>
                                    <h4 className='h_type'>{`${jobType !== "contract_hours" && isContractWork ? "계약 공수 - " : ""}`}
                                        {`${jobType !== "contract_hours" && !isContractWork ? "투입 공수 - " : ""}`}
                                        {`${keys2[index] !== "계약 공수" ? keys2[index] : ""}`}
                                        {`${jobType === "contract_hours" ? "- All " : ""}`}
                                        <a href="http://www.dataanalytics.co.kr/statBlog/2744" target="_blank" rel="noreferrer" className='lnk_type'>
                                            &nbsp;&nbsp;<i className="icon info circle"> <span>정규 분포표</span></i>
                                        </a>
                                    </h4>
                                    <ReactApexChart options={chartOptions} series={series} height={500} type="line" />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
        );
    }

export default React.memo(StatisticsSection);