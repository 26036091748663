import { FETCH_DATA_BEGIN, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE, LOAD_PROJECTS_SUCCESS, LOAD_PROJECTS_ERROR } from '../actions/dataActions';

const initialState = {
    projects: [],
    loading: false,
    error: null
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                projects: action.payload
            };
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case LOAD_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.payload
            };
        case LOAD_PROJECTS_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
};

export default dataReducer;
